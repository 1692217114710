import { Box, Button } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjAdminBaseData } from '../../../api/object/admin/ApiObjAdminBaseData';
import { ApiObjAdminUserHitrateInstance } from '../../../api/object/admin/ApiObjAdminUserHitrateInstance';
import { api_admin_user_hitrate_instance_list } from '../../../api/endpoint/admin/user/hitrate_instance/api_admin_user_hitrate_instance_list';
import { AdminUserTabHitrateDetails } from './AdminUserTabHitrateDetails';
import { AdminUserTabHitrateCustomFields } from './AdminUserTabHitrateCustomFields';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    adminBaseData: ApiObjAdminBaseData
    otherUserId: number
}

export const AdminUserTabHitrate: FunctionComponent <Props> = ({
    baseData,
    userData,
    adminBaseData,
    otherUserId
}) => {

    const EDIT_WINDOW_NONE = 1;
    const EDIT_WINDOW_NEW = 2;
    const EDIT_WINDOW_DETAILS = 3;
    const EDIT_WINDOW_MAPPING = 4;

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [hitrateInstanceList, setHitrateInstanceList] = useState <Array<ApiObjAdminUserHitrateInstance>> ([]);
    const [isLoading, setIsLoading] = useState <boolean> (true);
    const [isSaving, setIsSaving] = useState <boolean> (false);
    const isActive = !isLoading && !isSaving;
    const [editWindow, setEditWindow] = useState <number> (EDIT_WINDOW_NONE);
    const [selectedInstanceId, setSelectionInstanceId] = useState <number> (0);

    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {
        fetchList();
    }, [otherUserId]);

    const fetchList = () => {
        setIsLoading(true);
        api_admin_user_hitrate_instance_list(otherUserId)
            .then((result) => {
                setHitrateInstanceList(result);
                setIsLoading(false);
            });
    }

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionButtonNew = () => {
        setEditWindow(EDIT_WINDOW_NEW);
        setSelectionInstanceId(0);
    }

    const actionButtonEditDetails = (instanceId: number) => {
        setEditWindow(EDIT_WINDOW_DETAILS);
        setSelectionInstanceId(instanceId);
    }

    const actionButtonEditMapping = (instanceId: number) => {
        setEditWindow(EDIT_WINDOW_MAPPING);
        setSelectionInstanceId(instanceId);
    }

    const actionCallbackCloseAndReload = () => {
        setEditWindow(EDIT_WINDOW_NONE);
        setSelectionInstanceId(0);
        fetchList();
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderList = () : JSX.Element => {

        const elements : Array<JSX.Element> = [];
        for (const item of hitrateInstanceList) {
            elements.push(renderItem(item));
        }
        return (
            <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                {elements}
            </Box>
        )
    }

    const renderItem = (hitrateInstance: ApiObjAdminUserHitrateInstance) : JSX.Element => {

        return (
            <Box
                key={hitrateInstance.id}
                style={{backgroundColor: selectedInstanceId === hitrateInstance.id ? '#DDDDDD': undefined}}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}>
                    <Box width={'200px'}>
                        {hitrateInstance.public_name}
                    </Box>
                    <Box width={'300px'}>
                        {hitrateInstance.url}
                    </Box>
                    <Box width={'100px'}>
                        {hitrateInstance.is_active ? 'Aktiv' : 'Inaktiverad'}
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} gap={'10px'}>
                        <Button variant='contained' color='secondary' onClick={() => {actionButtonEditDetails(hitrateInstance.id)}} disabled={!isActive}>
                            Redigera
                        </Button>
                        <Button variant='contained' color='secondary' onClick={() => {actionButtonEditMapping(hitrateInstance.id)}} disabled={!isActive}>
                            Kundkortsfält
                        </Button>
                    </Box>
            </Box>
        );
    }

    const renderEditWindow = () : JSX.Element|undefined => {

        if (editWindow === EDIT_WINDOW_NEW) {
            return (
                <AdminUserTabHitrateDetails
                    baseData={baseData}
                    userData={userData}
                    adminBaseData={adminBaseData}
                    hitrateInstance={undefined}
                    otherUserId={otherUserId}
                    callbackCloseAndReload={actionCallbackCloseAndReload}
                />
            );

        } else if (editWindow === EDIT_WINDOW_DETAILS) {

            let instanceObj = undefined;
            for (const item of hitrateInstanceList) {
                if (item.id === selectedInstanceId) {
                    instanceObj = item;
                    break;
                }
            }
            if (instanceObj === undefined) {
                return undefined;
            }
            return (
                <AdminUserTabHitrateDetails
                    baseData={baseData}
                    userData={userData}
                    adminBaseData={adminBaseData}
                    hitrateInstance={instanceObj}
                    otherUserId={otherUserId}
                    callbackCloseAndReload={actionCallbackCloseAndReload}
                />
            );

        } else if (editWindow === EDIT_WINDOW_MAPPING) {
            let instanceObj = undefined;
            for (const item of hitrateInstanceList) {
                if (item.id === selectedInstanceId) {
                    instanceObj = item;
                    break;
                }
            }
            if (instanceObj === undefined) {
                return undefined;
            }
            return (
                <AdminUserTabHitrateCustomFields
                    baseData={baseData}
                    userData={userData}
                    adminBaseData={adminBaseData}
                    hitrateInstance={instanceObj}
                    otherUserId={otherUserId}
                    callbackCloseAndReload={actionCallbackCloseAndReload}
                />
            );
        }
        return undefined;
    }

    if (isLoading) {
        return (<Box padding={'14px'}>Laddar...</Box>);
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            {renderList()}
            <Box paddingTop={'15px'}>
                <Button variant='contained' color='secondary' onClick={actionButtonNew} disabled={!isActive}>
                    Lägg till
                </Button>
            </Box>
            {renderEditWindow()}
        </Box>
    );
}

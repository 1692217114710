import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export interface AdminUserHitrateInstancePatchParams {
    user_id?: number
    is_active?: boolean
    public_name?: string
    url?: string
    api_token?: string
    custom_fields?: Array<{export_field_id: number, custom_field_id: number}>
}

export const api_admin_user_hitrate_instance_patch = async (userId: number, hitrateInstanceId: number, data: AdminUserHitrateInstancePatchParams) : Promise<void> => {

    const url = API_URL+'/admin/user/'+userId+'/hitrate_instance/'+hitrateInstanceId;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    await Axios.patch(
        url,
        data,
        config
    );
}
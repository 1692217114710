import { FunctionComponent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, MenuItem, Typography, CircularProgress, LinearProgress, Button, Paper, ClickAwayListener, MenuList } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { UserManager } from '../../../data_layer/user/UserManager';
import { util_format_number } from '../../../util/format/util_format';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

type Props = {
    userData: UserData,
    userManager: UserManager,
    title: string
}

export const HeaderComp: FunctionComponent <Props> = ({
    userData,
    userManager,
    title,
}) => {

    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState <boolean> (false);

    // ==============================================================
    // === Render leads counter
    // ==============================================================

    const tryRenderCounterLeads = () : JSX.Element|undefined => {

        if (!userData.hasExportSubscription()) {
            return undefined;
        }

        if (location.pathname.includes("populate")) {
            return undefined;
        }

        const leadsMax = userData.getExportSubscriptionLeadsMax();
        const leadsLeft = userData.getExportSubscriptionLeadsLeft();
        
        if (leadsMax === 0) {
            return undefined;
        }

        const leftProgress = (leadsLeft / leadsMax) * 100;
        const leftString = util_format_number(leadsLeft);

        return (
        <Box width={320} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} mr={4}>
            <Box mb={1} textAlign={'center'}>Återstående leads: {leftString} st</Box>
            <LinearProgress color={'secondary'} variant="determinate" value={leftProgress} />
        </Box>
        );
    }

    const tryRenderCounterPopulate = () : JSX.Element|undefined => {

        if (!userData.hasPopulateFileSubscription()) {
            return undefined;
        }

        if (location.pathname.includes("order_leads")) {
            return undefined;
        }

        const populateMax = userData.getPopulateFileSubscriptionSpendingMax();
        const populateLeft = userData.getPopulateFileSubscriptionPotLeft();
        
        if (populateMax === 0) {
            return undefined;
        }

        const leftProgress = (populateLeft / populateMax) * 100;
        const leftString = util_format_number(populateLeft);

        return (
        <Box width={320} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} mr={4}>
            <Box mb={1} textAlign={'center'}>Nummersättningspott: {leftString} kr</Box>
            <LinearProgress color={'secondary'} variant="determinate" value={leftProgress} />
        </Box>
        );
    }

    // ==============================================================
    // === Render user
    // ==============================================================
  
    const renderUserElement = () => {

        if (!userData.isIdle()) {
            return (
                <CircularProgress size={16} />
            );
        }

        const handleClick = () => {
            setIsMenuOpen(!isMenuOpen);
        }

        if (userData.isLoggedIn()) {

            return (
                <Box display="flex" alignItems="center" height={'100%'}>
                    {tryRenderCounterLeads()}
                    {tryRenderCounterPopulate()}
                    <Box
                        display="flex"
                        alignItems="center"
                        height={'100%'}
                        paddingLeft={'14px'}
                        paddingRight={'14px'}
                        gap={'10px'}
                        bgcolor={isMenuOpen ? '#2a3066' : ''}
                        onClick={handleClick}
                        style={{cursor: 'pointer'}}>

                        <Typography variant="subtitle1">
                            {userData.getFirstName()}
                        </Typography>
                        <AccountCircleIcon fontSize='large'/>
                    </Box>
                </Box>
            );
        } else {

            return (
                <Box display="flex" alignItems="center" paddingRight={'14px'}>
                    
                    <Button
                        variant='outlined'
                        color={'secondary'}
                        onClick={() => { navigate('/account/register')}}>
                        {'Skapa konto'}
                    </Button>

                    <Button
                        style={{marginLeft: '8px'}}
                        variant='outlined'
                        color={'secondary'}
                        onClick={() => { navigate('/account/login')}}>
                        {'Logga in'}
                    </Button>
                    
                </Box>
            );
        }
    }

    // ==============================================================
    // === User menu
    // ==============================================================

    const tryRenderUserMenu = () : JSX.Element|undefined => {

        if (!userData.isLoggedIn()) {
            return undefined;
        }
        if (!isMenuOpen) {
            return undefined;
        }

        const handleClose = () => {
            setIsMenuOpen(false);
        }

        const handleListKeyDown = (event: React.KeyboardEvent) => {
            if (event.key === 'Tab') {
                event.preventDefault();
                setIsMenuOpen(false);
            } else if (event.key === 'Escape') {
                setIsMenuOpen(false);
            }
        }

        const handleNavigate = (path: string) => {
            navigate(path);
            setIsMenuOpen(false);
        }

        const handleLogout = () => {
            if (userData.isIdle()) {
                userManager.actionLogOut();
            }
            navigate('/');
            setIsMenuOpen(false);
        };

        return (
            <Box position={'fixed'} zIndex={'1'} top={'64px'} right={'0'}>
                
                    <ClickAwayListener onClickAway={handleClose}>
                        <Paper>
                        <MenuList
                            autoFocusItem={isMenuOpen}
                            onKeyDown={handleListKeyDown}
                        >
                            <MenuItem key={1} onClick={ () => { handleNavigate('/my_user'); }}>
                                <SettingsIcon fontSize='medium'/>
                                <Box paddingLeft={'10px'}>
                                    {'Användarinställningar'}
                                </Box>
                            </MenuItem>
                            <MenuItem key={2} onClick={handleLogout}>
                                <LogoutIcon fontSize='medium'/>
                                <Box paddingLeft={'10px'}>
                                    {'Logga ut'}
                                </Box>
                            </MenuItem>
                        </MenuList>
                        </Paper>
                    </ClickAwayListener>
                
            </Box>
        );
    }

    // ==============================================================
    // === Render main
    // ==============================================================

    return (
        <AppBar color='primary'>
            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' height={'64px'}>
                <Link to="/" style={{ textDecoration: "none" }}>                
                    <Typography marginLeft={'15px'}
                        variant='h5' color="white">
                        Målgruppsväljaren
                    </Typography>
                </Link>
                
                {renderUserElement()}
                
            </Box>
            {tryRenderUserMenu()}
        </AppBar>
    );
};

import { Autocomplete, Box, Button, MenuItem, Modal, Paper, Select, TextField, Tooltip, Typography, } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ApiObjBaseData } from '../../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../../data_layer/user/UserData';
import { ApiObjExport } from '../../../../api/object/export/ApiObjExport';
import { ApiObjHitrate } from '../../../../api/object/hitrate/ApiObjHitrate';
import { api_hitrate_list } from '../../../../api/endpoint/hitrate/api_hitrate_list';
import { api_hitrate_campaign_list } from '../../../../api/endpoint/hitrate/api_hitrate_campaign_list';
import { ApiObjHitrateCampaign } from '../../../../api/object/hitrate/ApiObjHitrateCampaign';
import { api_hitrate_contact_list_create, HitrateContactListCreateResult } from '../../../../api/endpoint/hitrate/api_hitrate_contact_list_create';
import { ApiObjExportField } from '../../../../api/object/export/ApiObjExportField';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import { useNavigate } from 'react-router-dom';

type Props = {
    userData: UserData,
    baseData: ApiObjBaseData,
    exportObj: ApiObjExport,
    onClose: () => void,
}

export const HitrateExportModal: FunctionComponent<Props> = ({
    userData,
    baseData,
    exportObj,
    onClose
}) => {

    // ===========================================================
    // === Settings
    // ===========================================================

    const STANDARD_EXPORT_FIELDS = [
        ApiObjExportField.ID_7_PHONE_MOBILE,
        ApiObjExportField.ID_8_PHONE_FIXED,
        ApiObjExportField.ID_2_FIRST_NAME,
        ApiObjExportField.ID_3_LAST_NAME,
        ApiObjExportField.ID_24_PERSONAL_ID,
        ApiObjExportField.ID_4_ADDRESS_STREET,
        ApiObjExportField.ID_5_ZIPCODE,
        ApiObjExportField.ID_6_ADDRESS_CITY,
        ApiObjExportField.ID_15_CONTACTS_ID,
    ];

    // ===========================================================
    // === States
    // ===========================================================

    const STATE_1_LOADING_HITRATE = 1;
    const STATE_2_LOADING_HITRATE_FAILED = 2;
    const STATE_3_LOADING_CAMPAIGNS = 3;
    const STATE_4_LOADING_CAMPAIGNS_FAILED = 4;
    const STATE_5_IDLE = 5;
    const STATE_6_EXPORTING = 6;
    const STATE_7_EXPORT_FAILED = 7;
    const STATE_8_EXPORT_SUCCESS = 8;

    // ===========================================================
    // === State
    // ===========================================================

    const [hitrateList, setHitrateList] = useState <Array<ApiObjHitrate>> ([]);
    const [hitrateCampaignList, setHitrateCampaignList] = useState <Array<ApiObjHitrateCampaign>> ([]);
    const [selectedHitrateId, setSelectedHitrateId] = useState <number> (0);
    const [selectedHitrateCampaignId, setSelectedHitrateCampaignId] = useState <number> (0);
    const [state, setState] = useState <number> (STATE_1_LOADING_HITRATE);
    const [contactListResult, setContactListResult] = useState <undefined|HitrateContactListCreateResult> (undefined);

    // ===========================================================
    // === Setup
    // ===========================================================

    const navigate = useNavigate();

    useEffect(() => {
        fetchHitrate();
    }, []);

    // ===========================================================
    // === Fetch
    // ===========================================================

    const fetchHitrate = async () => {
        setState(STATE_1_LOADING_HITRATE);

        try {

            const result = await api_hitrate_list();
            if (result.length === 0) {
                return;
            }


            let tempHitrateId = 0;
            const tempSelectedHitrateId = localStorage.getItem("selectedHitrateId");
            if (tempSelectedHitrateId !== null) {
                const tempInt = parseInt(tempSelectedHitrateId);
                if (!isNaN(tempInt)) {
                    tempHitrateId = tempInt;
                }
            }
            if (tempHitrateId === 0) {
                tempHitrateId = result[0].id;
            }

            localStorage.setItem("selectedHitrateId", tempHitrateId + '');
            setSelectedHitrateId(tempHitrateId);
            setHitrateList(result);

            let hitrateFound = false;
            for (const item of result) {
                if (item.id === tempHitrateId) {
                    hitrateFound = true;
                    break;
                }
            }
            if (hitrateFound) {
                fetchCampaigns(tempHitrateId);
            } else {
                // Selected hitrate id not found in list.
                // Stop instead of fetching campaigns.
                setState(STATE_5_IDLE);
            }

        } catch(err) {
            setState(STATE_2_LOADING_HITRATE_FAILED);
            console.error(err);
        }
    }

    const fetchCampaigns = async (hitrateId: number) => {
        setState(STATE_3_LOADING_CAMPAIGNS);

        try {
            const result = await api_hitrate_campaign_list(hitrateId);
            setHitrateCampaignList(result);
            setState(STATE_5_IDLE);

        } catch(err) {
            setState(STATE_4_LOADING_CAMPAIGNS_FAILED);
            console.error(err);
        }
    }

    // ===========================================================
    // === Helpers
    // ===========================================================

    const getUsedExportFields = () : Array<ApiObjExportField> => {

        let hitrateObj = undefined;
        for (const item of hitrateList) {
            if (item.id === selectedHitrateId) {
                hitrateObj = item;
                break;
            }
        }
        if (hitrateObj === undefined) {
            return [];
        }

        const usedExportFieldIds = exportObj.params.export_field_ids;
        const exportFields = userData.getExportFields();

        const mappedExportFields = [];
        for (const item of hitrateObj.custom_fields) {
            mappedExportFields.push(item.export_field_id);
        }
        
        const result = [];
        for (const item of exportFields) {

            const isValid = (
                usedExportFieldIds.includes(item.id)
                && (
                    STANDARD_EXPORT_FIELDS.includes(item.id)
                    || mappedExportFields.includes(item.id)
                )
            );
            if (!isValid) {
                continue;
            }
            result.push(item);
        }
        return result;
    }

    const getUnmappedExportFields = () => {
        let hitrateObj = undefined;
        for (const item of hitrateList) {
            if (item.id === selectedHitrateId) {
                hitrateObj = item;
                break;
            }
        }
        if (hitrateObj === undefined) {
            return [];
        }

        const usedExportFieldIds = exportObj.params.export_field_ids;
        const exportFields = userData.getExportFields();

        const mappedExportFields = [];
        for (const item of hitrateObj.custom_fields) {
            mappedExportFields.push(item.export_field_id);
        }
        
        const result = [];
        for (const item of exportFields) {
            if (!usedExportFieldIds.includes(item.id)) {
                continue;
            }
            if (STANDARD_EXPORT_FIELDS.includes(item.id)) {
                continue;
            }
            if (mappedExportFields.includes(item.id)) {
                continue;
            }
            result.push(item);
        }
        return result;
    }

    // ===========================================================
    // === Actions
    // ===========================================================

    const actionSelectHitrate = (hitrateId: number) => {
        setSelectedHitrateId(hitrateId);
        setSelectedHitrateCampaignId(0);
        fetchCampaigns(hitrateId);
    }

    const actionMakeExport = async () => {

        if (state !== STATE_5_IDLE) {
            return;
        }
        setState(STATE_6_EXPORTING);
        try {
            const result = await api_hitrate_contact_list_create(selectedHitrateId, exportObj.id, selectedHitrateCampaignId);
            setContactListResult(result);
            setState(STATE_8_EXPORT_SUCCESS);
            localStorage.setItem("selectedHitrateId", selectedHitrateId + '');

        } catch(err) {
            setState(STATE_7_EXPORT_FAILED);
            console.error(err);
        }
    }

    // ===========================================================
    // === Render Sections
    // ===========================================================

    const renderSelectHitrate = () : JSX.Element => {

        const options = [];
        options.push(
            <MenuItem
                key={0}
                value={0}>
                    -
            </MenuItem>
        );
        for (const item of hitrateList) {
            options.push(
                <MenuItem
                    key={item.id}
                    value={item.id}>
                        {item.public_name}
                </MenuItem>
            );
        }

        let selectedId = selectedHitrateId;
        if (hitrateList.length === 0) {
            selectedId = 0;
        }

        return (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'start'}>
                <Box width={'120px'}>
                    Instans
                </Box>
                <Select
                    value={selectedId}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        if (typeof newValue === 'number') {
                            actionSelectHitrate(newValue);
                        }
                    }}
                    disabled={state !== STATE_5_IDLE}>
                        {options}
                </Select>
            </Box>
        );
    }

    const renderSelectCampaign = () : JSX.Element => {

        // const options = [];
        // options.push(
        //     <MenuItem
        //         key={0}
        //         value={0}>
        //             - Ingen specifik kampanj -
        //     </MenuItem>
        // );
        // for (const item of hitrateCampaignList) {
        //     options.push(
        //         <MenuItem
        //             key={item.id}
        //             value={item.id}>
        //                 {item.name}
        //         </MenuItem>
        //     );
        // }

        const options : Array<{label: string, value: number}> = [];
        for (const item of hitrateCampaignList) {
            options.push({
                label: item.name,
                value: item.id
            });
        }

        return (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'start'}>
                <Box width={'120px'}>
                    Kampanj
                </Box>
                
                <Autocomplete
                    disablePortal
                    isOptionEqualToValue={(option) => option.value === selectedHitrateCampaignId}
                    options={options}
                    sx={{ width: '320px' }}
                    onChange={ (event: any, newValue) => {

                        if (newValue === null) {
                            setSelectedHitrateCampaignId(0);

                        } else if (typeof newValue.value === 'number') {
                            setSelectedHitrateCampaignId(newValue.value);
                        }
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.value}>
                                {option.label}
                            </li>
                        );
                    }}
                    renderInput={(params) => <TextField {...params} label="Välj" />}
                />
                
            </Box>
        );

        // <Select
        //     value={selectedHitrateCampaignId}
        //     onChange={(event) => {
        //         const newValue = event.target.value;
        //         if (typeof newValue === 'number') {
        //             setSelectedHitrateCampaignId(newValue);
        //         }
        //     }}
        //     disabled={state !== STATE_5_IDLE}>
        //         {options}
        // </Select>

        // <Autocomplete
        //     disablePortal
        //     isOptionEqualToValue={(option) => option.value === selectedTargetGroupId}
        //     options={targetGroups.map((item) => ({
        //         label: item.name,
        //         value: item.id
        //     }))}
        //     sx={{ width: '276px' }}
        //     onChange={ (event: any, newValue) => {
        //         if(!newValue) return
        //         actionTargetGroupSelected(newValue.value)
        //     }}
        //     renderInput={(params) => <TextField {...params} label="Välj" />}
        // />
    }

    const renderButtons = () : JSX.Element => {

        let statusElement = undefined;
        if (state === STATE_6_EXPORTING) {
            statusElement = (
                <Box>
                    Skickar till Hitrate...
                </Box>
            );
        }

        return (
            <Box display={'flex'} flexDirection={'row'} justifyContent={'start'} alignItems={'center'} gap={'10px'}>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={actionMakeExport}
                    disabled={state !== STATE_5_IDLE}>
                        Skicka
                </Button>
                {statusElement}
            </Box>
        );
    }

    const renderDataInfo = () : JSX.Element | undefined => {

        const usedFields = getUsedExportFields();
        const unmappedFields = getUnmappedExportFields();
        
        const toolTipTextUsed = [];
        for (const item of usedFields) {
            toolTipTextUsed.push(<Box fontSize={'14px'} key={item.id}>{item.name}</Box>);
        }

        const toolTipTextUnmapped = [];
        for (const item of unmappedFields) {
            toolTipTextUnmapped.push(<Box fontSize={'14px'} key={item.id}>{item.name}</Box>);
        }
        
        const textUsed = usedFields.length + ' st skickas';
        const textUnmapped = unmappedFields.length + ' st ej mappade';

        return (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'start'}>
                <Box width={'120px'}>
                    Leveransfält
                </Box>
                <Box width={'350px'} display={'flex'} flexDirection={'row'} gap={'16px'}>
                    <Box>
                        <Tooltip title={
                            <React.Fragment>
                                <Box display={'flex'} flexDirection={'column'}>
                                    {toolTipTextUsed}
                                </Box>
                            </React.Fragment>
                        }>
                            <Button>{textUsed}</Button>
                        </Tooltip>
                    </Box>
                    <Box>
                        <Tooltip title={
                            <React.Fragment>
                                <Box display={'flex'} flexDirection={'column'}>
                                    {toolTipTextUnmapped}
                                </Box>
                            </React.Fragment>
                        }>
                            <Button
                                onClick={() => { navigate('/my_user/hitrate') }}
                                endIcon={<ShortcutIcon />} >
                                    {textUnmapped}
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        );
    }

    // ===========================================================
    // === Render Main
    // ===========================================================

    const renderMain = () : JSX.Element => {

        if (state === STATE_8_EXPORT_SUCCESS) {

            let selectedCampaignName = '- Ingen specifik -';
            if (selectedHitrateCampaignId !== 0) {
                for (const item of hitrateCampaignList) {
                    if (item.id === selectedHitrateCampaignId) {
                        selectedCampaignName = item.name;
                        break;
                    }
                }
            }

            return (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'10px'}
                    justifyContent={'space-between'}
                    style={styleScroll}
                    width={'472px'}
                    height={'270px'}
                    padding={'14px'}>
                    
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography variant='h5'>Klart!</Typography>
                    </Box>
                    <Box>
                        <Typography variant='body2'>
                            En ny kontaktlista är skapad i din anslutna instans av Hitrate.
                        </Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} gap={'6px'} paddingTop={'10px'}>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                            <Box>Namn på kontaktlista:</Box>
                            <Box>{exportObj.name}</Box>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                            <Box>Kampanj:</Box>
                            <Box>{selectedCampaignName}</Box>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                            <Box>Antal personer i orginalfil:</Box>
                            <Box>{contactListResult?.export_person_count}</Box>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                            <Box>Antal personer med telefonnummer:</Box>
                            <Box>{contactListResult?.valid_person_count}</Box>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                            <Box>Antal fel:</Box>
                            <Box>{contactListResult?.failed_person_count}</Box>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} >
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={onClose}>
                                    Ok
                            </Button>
                        </Box>
                    </Box>
                </Box>
            );
        }

        if (state === STATE_7_EXPORT_FAILED) {
            return (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'10px'}
                    justifyContent={'space-between'}
                    style={styleScroll}
                    width={'500px'}
                    height={'150px'}
                    padding={'14px'}>
                    
                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography variant='h5'>Fel</Typography>
                    </Box>
                    <Box>
                        <Typography variant='body2'>
                            Ett fel uppstod.
                        </Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} gap={'6px'} paddingTop={'10px'}>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} >
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={onClose}>
                                    Ok
                            </Button>
                        </Box>
                    </Box>
                </Box>
            );
        }

        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                style={styleScroll}
                width={'500px'}
                height={'290px'}
                padding={'14px'}>

                <Box 
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'10px'}>

                    <Box display={'flex'} flexDirection={'column'}>
                        <Typography variant='h5'>Skicka till Hitrate</Typography>
                    </Box>
                    {renderSelectHitrate()}
                    {renderSelectCampaign()}
                    {renderDataInfo()}
                </Box>
                <Box>
                    {renderButtons()}
                </Box>
            </Box>
        );
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: '290px'
    } as React.CSSProperties;

    const styleScroll = {
        overflowY: 'auto'
    } as React.CSSProperties;

    return (
        <Modal
            open={true}
            onClose={onClose}>

            <Box style={style}>
                <Paper>
                    {renderMain()}
                </Paper>
            </Box>

        </Modal>
    );
}
import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjHitrate } from '../../object/hitrate/ApiObjHitrate';

export const api_hitrate_patch = async (hitrateId: number, customFields: Array<{export_field_id: number, custom_field_id: number}>) : Promise<ApiObjHitrate> => {

    const url = API_URL+'/hitrate/'+hitrateId;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };

    const body = {
        custom_fields: customFields
    };

    const resp = await Axios.patch(
        url,
        body,
        config
    );

    return new ApiObjHitrate(resp.data);
}

import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export interface AdminUserHitrateInstanceCreateParams {
    is_active: boolean
    public_name: string
    url: string
    api_token: string
}

export const api_admin_user_hitrate_instance_create = async (userId: number, data: AdminUserHitrateInstanceCreateParams) : Promise<void> => {

    const url = API_URL+'/admin/user/'+userId+'/hitrate_instance';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    await Axios.post(
        url,
        data,
        config
    );
}
import { ApiObjExportField } from '../../api/object/export/ApiObjExportField';
import { ApiObjFilter } from '../../api/object/filter/ApiObjFilter';
import { ApiObjHouseholdOption } from '../../api/object/household/ApiObjHouseholdOption';
import { ApiObjNixOption } from '../../api/object/nix_option/ApiObjNixOption';
import { ApiObjPriceObject } from '../../api/object/price/ApiObjPriceObject';
import { ApiObjSource } from '../../api/object/source/ApiObjSource';
import { ApiObjUserMy } from '../../api/object/user/ApiObjUserMy';

/**
 * Holds data and state related to the current logged in user.
 * Passed to all screens which needs user data.
 */
export class UserData {

    // ==========================================================================
    // === Variables
    // ==========================================================================

    public static readonly STATE_INITIAL = 1;
    public static readonly STATE_TRY_FETCHING_USER = 2;
    public static readonly STATE_LOGGING_IN = 3;
    public static readonly STATE_LOGIN_FAILED = 4;
    public static readonly STATE_LOGGING_OUT = 5;
    public static readonly STATE_UPDATING_USER = 6;
    public static readonly STATE_UPDATE_USER_FAILED = 7;
    public static readonly STATE_READY_LOGGED_OUT = 8;
    public static readonly STATE_READY_LOGGED_IN = 9;

    private static readonly STATES_IDLE = [
        UserData.STATE_LOGIN_FAILED,
        UserData.STATE_UPDATE_USER_FAILED,
        UserData.STATE_READY_LOGGED_IN,
        UserData.STATE_READY_LOGGED_OUT
    ];

    private static readonly STATES_LOGGED_IN = [
        UserData.STATE_LOGGING_OUT,
        UserData.STATE_UPDATING_USER,
        UserData.STATE_UPDATE_USER_FAILED,
        UserData.STATE_READY_LOGGED_IN
    ];

    // ==========================================================================
    // === Variables
    // ==========================================================================

    public state: number;
    public lastErrorMessage: string;
    public api_data: undefined|ApiObjUserMy;

    // ==========================================================================
    // === Create
    // ==========================================================================

    public static createNew = () : UserData => {
        return new UserData(undefined);
    }

    public static createClone = (original: UserData) : UserData => {
        return new UserData(original);
    }

    private constructor(old : UserData|undefined = undefined) {

        if (old === undefined) {
            this.state = UserData.STATE_INITIAL;
            this.lastErrorMessage = '';
            this.api_data = undefined;
        } else {
            this.state = old.state;
            this.lastErrorMessage = old.lastErrorMessage;
            this.api_data = old.api_data;   // Api data is imutable no need to deep copy.
        }
    }

    // ==========================================================================
    // === Get states
    // ==========================================================================

    /**
     * Data is ready to be used, nothing is loading, but user might not be logged in.
     * 
     * ApiUserData: Maybe
     * Actions: Yes.
     */
    public isIdle = () : boolean => {
        return UserData.STATES_IDLE.includes(this.state);
    }

    /**
     * User is currently logged in. Loading might be in progress.
     * 
     * ApiUserData: Yes
     * Actions: Maybe
     */
    public isLoggedIn = () : boolean => {
        return UserData.STATES_LOGGED_IN.includes(this.state);
    }

    public isLoggedInAndAdmin = () : boolean => {
        return this.isLoggedIn()
            && this.api_data !== undefined
            && this.api_data.is_admin
    }

    // ==========================================================================
    // === Get For Convenience
    // ==========================================================================
    
    public isFilterEnabledForExport = (filter: ApiObjFilter) : boolean => {
        if (filter.getFilterIdPremiumExport() === 0) {
            return true;
        }
        return this.isLoggedIn()
            && this.api_data !== undefined
            && this.api_data.enabled_filters_for_export.includes(filter.getFilterIdPremiumExport());
    }

    public isFilterEnabledForMarketing = (filter: ApiObjFilter) : boolean => {
        if (filter.getFilterIdPremiumMarketing() === 0) {
            return true;
        }
        return this.isLoggedIn()
            && this.api_data !== undefined
            && this.api_data.enabled_filters_for_marketing.includes(filter.getFilterIdPremiumMarketing());
    }

    public hasExportSubscription = () : boolean => {
        return this.isLoggedIn()
            && this.api_data !== undefined
            && this.api_data.hasExportSubscription();
    }

    public getExportSubscriptionLeadsLeft = () : number => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.getSubscriptionLeadsLeft();
        }
        return 0;
    }

    public getExportSubscriptionLeadsMax = () : number => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.export_subscription.leads_max;
        }
        return 0;
    }

    public hasPopulateFileSubscription = () : boolean => {
        return this.isLoggedIn()
            && this.api_data !== undefined
            && this.api_data.hasPopulateSubscription();
    }

    public getPopulateFileSubscriptionPotLeft = () : number => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.getPopulatePotLeft();
        }
        return 0;
    }

    public getPopulateFileSubscriptionSpendingMax = () : number => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.populate_file_subscription.spending_max;
        }
        return 0;
    }

    public getSource = (sourceId: number) : ApiObjSource | undefined => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.getSource(sourceId);
        }
        return undefined;
    }

    public getSources = () : Array<ApiObjSource> => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.enabled_sources;
        }
        return [];
    }

    public getExportField = (exportFieldId: number) : ApiObjExportField | undefined => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.getExportField(exportFieldId);
        }
        return undefined;
    }

    public getExportFields = () : Array<ApiObjExportField> => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.enabled_export_fields;
        }
        return [];
    }

    public getPriceObjectFromId = (priceObjectId: number) : ApiObjPriceObject | undefined => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.getPriceObjectFromId(priceObjectId);
        }
        return undefined;
    }
    
    public getPriceObjectFromType = (priceObjectTypeId: number, objectId: number) : ApiObjPriceObject | undefined => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.getPriceObjectFromType(priceObjectTypeId, objectId);
        }
        return undefined;
    }

    public getNixOption = (nixOptionId: number) : ApiObjNixOption | undefined => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.getNixOption(nixOptionId);
        }
        return undefined;
    }

    public getNixOptions = () : Array<ApiObjNixOption> => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.nix_options;
        }
        return [];
    }

    public getHouseholdOptions = () : Array<ApiObjHouseholdOption> => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.household_options;
        }
        return [];
    }

    public getFirstName = () : string => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.first_name;
        }
        return '';
    }

    public getLastName = () : string => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.last_name;
        }
        return '';
    }

    public getCompanyName = () : string => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.company_name;
        }
        return '';
    }

    public getCompanyOrgNumber = () : string => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.company_org_number;
        }
        return '';
    }

    public getAddress = () : string => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.address;
        }
        return '';
    }

    public getZipcode = () : string => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.zipcode;
        }
        return '';
    }

    public getCity = () : string => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.city;
        }
        return '';
    }

    public getPhoneNumber = () : string => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.phone_number;
        }
        return '';
    }

    public isFacebookEnabled = () : boolean => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.is_facebook_enabled;
        }
        return false;
    }

    public isHitrateEnabled = () : boolean => {
        if (this.isLoggedIn() && this.api_data !== undefined) {
            return this.api_data.is_hitrate_enabled;
        }
        return false;
    }
}
import { Button, Paper, Box, TextField, InputLabel, Select, SelectChangeEvent, MenuItem, FormControl, ListSubheader } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoUser } from '../../error/ErrorPageNoUser';
import { api_get_target_groups } from '../../../api/endpoint/target_group/api_get_target_groups';
import { ApiObjTargetGroup } from '../../../api/object/target_group/ApiObjTargetGroup';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import { ApiObjExport } from '../../../api/object/export/ApiObjExport';
import { api_get_exports } from '../../../api/endpoint/export/api_get_exports';
import { api_create_target_group } from '../../../api/endpoint/target_group/api_create_target_group';
import { api_update_target_group } from '../../../api/endpoint/target_group/api_update_target_group';
import { FacebookTargetGroupModal } from '../../shared_components/FacebookTargetGroupModal';
import { util_format_datetime, util_format_number } from '../../../util/format/util_format';

type RowData = {
    id: number,
    name: string,
    person_count: string,
    created_at: string,
    on_facebook: boolean,
}

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
}

export const TargetGroupListPage: FunctionComponent <Props> = ({
    baseData,
    userData,
}) => {

    if (!userData.isLoggedIn()) {
        return <ErrorPageNoUser />;
    }

    const navigate = useNavigate();
    const [targetGroupObjs, setTargetGroupObjs] = useState <Array<ApiObjTargetGroup>> ([]);
    const [exportObjs, setExportObjs] = useState <Array<ApiObjExport>> ([]);

    const [newOpen, setNewOpen] = useState <boolean> (false);
    const [newName, setNewName] = useState <string> ('');
    const [newMethod, setNewMethod] = useState <number> (1);
    const [newMethodItem, setNewMethodItem] = useState <number> (0);
    const [newIsSaving, setNewIsSaving] = useState <boolean> (false);

    const [facebookModalObject, setFacebookModalObject] = useState <undefined|ApiObjTargetGroup> (undefined);

    useEffect(() => {
        fetchTargetGroups();
        fetchExports();
    }, []);
  
    const fetchTargetGroups = () => {
        api_get_target_groups()
            .then((resp) => {
                setTargetGroupObjs(resp);
            }).catch((err) => {
                console.error("Could not fetch target groups");
                console.error(err);
            });
    }

    const fetchExports = () => {
        api_get_exports()
            .then((resp) => {
                const tempObjs = [];
                for (const item of resp) {
                    if (item.state === ApiObjExport.STATE_7_READY) {
                        tempObjs.push(item);
                    }
                }
                setExportObjs(tempObjs);
            }).catch((err) => {
                console.error("Could not fetch exports");
                console.error(err);
            });
    }

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionSetNewName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewName(event.target.value);
    }

    const actionSetNewMethod = (event: SelectChangeEvent<number>) => {
        const newValue = event.target.value;
        if (typeof newValue === 'number') {
            setNewMethod(newValue);
            setNewMethodItem(0);
        }
    }

    const actionSetNewMethodItem = (event: SelectChangeEvent<number>) => {
        const newValue = event.target.value;
        if (typeof newValue === 'number') {
            setNewMethodItem(newValue);
        }
    }

    const actionCreateTargetGroup = () => {
        if (canCreateTargetGroup()) {
            setNewIsSaving(true);
            api_create_target_group({name: newName})
                .then((resp) => {
                    actionTargetGroupCreated(resp);

                }).catch((err) => {
                    console.error("Could not create target group.");
                    console.error(err);
                    setNewIsSaving(false);
                    // TODO ERIK felmeddelande till användaren.
                });
        }
    }

    const actionTargetGroupCreated = (newTargetGroup: ApiObjTargetGroup) => {

        if (newMethod === 1) {
            navigate('/my_target_groups/'+newTargetGroup.id);
            setNewIsSaving(false);

        } else if (newMethod === 2) {

            let targetGroup = undefined;
            for (const item of targetGroupObjs) {
                if (item.id === newMethodItem) {
                    targetGroup = item;
                    break;
                }
            }
            
            if (targetGroup === undefined) {
                console.error("Could not find old target group to populate new target with data.");
                return;
            }

            const indata = {
                id: newTargetGroup.id,
                source_id: targetGroup.source_id,
                nix_option_id: targetGroup.nix_option_id,
                filter_ids: targetGroup.filter_ids,
                map_areas: targetGroup.map_areas,
                export_field_ids: targetGroup.export_field_ids,
                stopfile_ids: targetGroup.stopfile_ids,
                stopfile_days: targetGroup.stopfile_days,
                household_option_id: targetGroup.household_option_id,
            }

            api_update_target_group(indata)
                .then((resp) => {
                    fetchTargetGroups();
                    setNewName('');
                    setNewMethod(1);
                    setNewMethodItem(0);
                    setNewIsSaving(false);

                }).catch((err) => {
                    console.error("Error when updating target group with new data.");
                    console.error(err);
                    fetchTargetGroups();
                    setNewIsSaving(false);
                });

        } else if (newMethod === 3) {

            let exportObj = undefined;
            for (const item of exportObjs) {
                if (item.id === newMethodItem) {
                    exportObj = item;
                    break;
                }
            }
            
            if (exportObj === undefined) {
                console.error("Could not find export to populate new target with data.");
                return;
            }

            const indata = {
                id: newTargetGroup.id,
                source_id: exportObj.params.source_id,
                nix_option_id: exportObj.params.nix_option_id,
                filter_ids: exportObj.params.filter_ids,
                map_areas: exportObj.params.map_areas,
                export_field_ids: exportObj.params.export_field_ids,
                stopfile_ids: [],
                stopfile_days: 0,
                household_option_id: exportObj.params.household_option_id,
            }
            
            api_update_target_group(indata)
                .then((resp) => {
                    fetchTargetGroups();
                    setNewName('');
                    setNewMethod(1);
                    setNewMethodItem(0);
                    setNewIsSaving(false);

                }).catch((err) => {
                    console.error("Error when updating target group with new data.");
                    console.error(err);
                    fetchTargetGroups();
                    setNewIsSaving(false);
                });
        }
    }

    const actionOpenFacebookModal = (targetGroupId: number) => {

        if (targetGroupObjs === undefined) {
            return;
        }

        let foundTargetGroup = undefined;
        for (const item of targetGroupObjs) {
            if (item.id === targetGroupId) {
                foundTargetGroup = item;
                break;
            }
        }
        setFacebookModalObject(foundTargetGroup);
    }

    const actionCloseFacebookModal = () => {
        setFacebookModalObject(undefined);
        fetchTargetGroups();
    }

    // ======================================================================
    // === Helpers
    // ======================================================================

    const canCreateTargetGroup = () : boolean => {
        return newName.length > 0
            && newName.length <= 50
            && (
                newMethod === 1
                || (newMethod === 2 && newMethodItem > 0)
                || (newMethod === 3 && newMethodItem > 0)
            );
    }

    // ======================================================================
    // === Render new form
    // ======================================================================

    const tryRenderNewForm = () : undefined|JSX.Element => {

        if (!newOpen) {
            return undefined
        }

        let elementMethodItem = undefined;
        if (newMethod === 2 || newMethod == 3) {

            let tempLabel = '';
            if (newMethod === 2) {
                tempLabel = 'Målgrupp';
            } else if (newMethod === 3) {
                tempLabel = 'Export';
            }

            const menuItems = [];
            menuItems.push(
                <MenuItem
                    key={0}
                    value={0}>
                        {'- Välj -'}
                </MenuItem>
            );

            if (newMethod === 2) {

                const groupsPublic = [];
                const groupsMy = [];
                for (const item of targetGroupObjs) {
                    if (item.user_id === 0) {
                        groupsPublic.push(item);
                    } else {
                        groupsMy.push(item);
                    }
                }

                menuItems.push(<ListSubheader key={'g1'}>Standard</ListSubheader>);

                for (const item of groupsPublic) {
                    menuItems.push(
                        <MenuItem
                            key={item.id}
                            value={item.id}>
                                {item.name}
                        </MenuItem>
                    );
                }

                menuItems.push(<ListSubheader key={'g2'}>Mina målgrupper</ListSubheader>);

                for (const item of groupsMy) {
                    menuItems.push(
                        <MenuItem
                            key={item.id}
                            value={item.id}>
                                {item.name}
                        </MenuItem>
                    );
                }

            } else if (newMethod === 3) {
                for (const item of exportObjs) {
                    menuItems.push(
                        <MenuItem
                            key={item.id}
                            value={item.id}>
                                {item.name}
                        </MenuItem>
                    );
                }
            }

            elementMethodItem = (
                <FormControl>
                    <InputLabel id='label_new_method_item'>{tempLabel}</InputLabel>
                    <Select
                        style={{minWidth: '200px'}}
                        label={tempLabel}
                        labelId='label_new_method_item'
                        value={newMethodItem}
                        onChange={(event) => {actionSetNewMethodItem(event)}}
                        disabled={newIsSaving}>

                        {menuItems}

                    </Select>
                </FormControl>
            );
        }

        return (
            <Box padding={'20px'}>
                <Box>
                    <TextField
                        label={'Namn'}
                        disabled={newIsSaving}
                        variant="outlined"
                        value={newName}
                        onChange={(event) => actionSetNewName(event)}
                        style = {{width: '300px'}}
                    />
                </Box>
                <Box marginTop={'20px'} display={'flex'} flexDirection={'row'}>
                    <Box>
                        <FormControl>
                            <InputLabel id='label_new_method'>Kopiera innehåll från</InputLabel>
                            <Select
                                style={{minWidth: '200px'}}
                                label={'Kopiera innehåll från'}
                                labelId='label_new_method'
                                value={newMethod}
                                onChange={(event) => {actionSetNewMethod(event)}}
                                disabled={newIsSaving}>

                                <MenuItem
                                    key={1}
                                    value={1}>
                                        {'Ingen'}
                                </MenuItem>
                                <MenuItem
                                    key={2}
                                    value={2}>
                                        {'Annan målgrupp'}
                                </MenuItem>
                                <MenuItem
                                    key={3}
                                    value={3}>
                                        {'Export'}
                                </MenuItem>

                            </Select>
                        </FormControl>
                    </Box>
                    <Box marginLeft={'20px'}>
                        {elementMethodItem}
                    </Box>
                </Box>
                <Box marginTop={'20px'}>
                    <Button
                        variant='contained'
                        color='secondary'
                        disabled={!canCreateTargetGroup()}
                        onClick={actionCreateTargetGroup}>
                            Skapa
                    </Button>
                </Box>
            </Box>
        );
    }

    // ======================================================================
    // === List content
    // ======================================================================

    const columnsBase = [

        {
            field: 'name',
            headerName: 'Namn',
            width: 250,
            editable: false,
            renderCell: (params: any) => {
                return (<Link to={'/my_target_groups/'+params.row.id}>{params.row.name}</Link>);
            }
        },
        {
            field: 'person_count',
            headerName: 'Antal personer',
            width: 150,
            editable: false
        },
        {
            field: 'created_at',
            headerName: 'Skapad',
            width: 200,
            editable: false,
        },
    ];

    const columnsFacebook = [
        {
            field: 'actions',
            headerName: 'Meta',
            width: 250,
            editable: false,
            renderCell: (params: any) => {
                
                //let checkIcon = undefined;
                let buttonText = 'Dela till Meta';
                let buttonVariant : 'contained'|'outlined' = 'contained';
                let buttonColor : 'primary'|'secondary' = 'secondary'
                if (params.row.on_facebook) {
                  //  checkIcon = <CheckIcon />;
                    buttonText = 'Redigera delning';
                    buttonVariant = 'outlined';
                    buttonColor = 'primary';
                }

                const targetGroupId = params.row.id;
                
                return (
                    <Button
                        variant={buttonVariant}
                        type={'button'}
                        color={buttonColor}
                        size={'small'}
                        onClick={(e) => {
                            // e.preventDefault();
                            // window.open(url);
                            actionOpenFacebookModal(targetGroupId);
                        }}>
                            {buttonText}
                    </Button>
                );
            }
        },
    ];

    const getColumns = () => {
        if (userData.isFacebookEnabled()) {
            return [
                ...columnsBase,
                ...columnsFacebook
            ];
        } else {
            return columnsBase;
        }
    }

    const getRows = () => {
        const ret : Array<RowData> = [];
        for (const item of targetGroupObjs) {
            if (item.user_id !== 0) {
                ret.push({
                    id: item.id,
                    name: item.name,
                    person_count: (item.person_count >= 0 ? util_format_number(item.person_count) : ''),
                    created_at: util_format_datetime(item.created_at),
                    on_facebook: item.facebook_target_group !== undefined
                });
            }
        }
        return ret;
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const tryRenderFacebookModal = () : undefined|JSX.Element => {

        if (facebookModalObject === undefined) {
            return undefined;
        }

        return (
            <FacebookTargetGroupModal
                target_group={facebookModalObject}
                new_target_group_data={undefined}
                baseData={baseData}
                userData={userData}
                onClose={actionCloseFacebookModal}
            />
        );
    }

    return (
        <Box>
            <Paper>
                <Box>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'start'} padding={'20px'}>
                        <Button
                            variant={'contained'}
                            startIcon={newOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            color={'secondary'}
                            onClick={() => { setNewOpen(!newOpen) }}>
                                Skapa Målgrupp
                        </Button>
                    </Box>
                    {tryRenderNewForm()}
                    <Box height={'770px'}>
                        <DataGrid
                            rows={getRows()}
                            columns={getColumns()}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            checkboxSelection={false}
                            isRowSelectable={() => {return false;}}
                        />
                    </Box>
                </Box>
                {tryRenderFacebookModal()}
            </Paper>
        </Box>
    );
}

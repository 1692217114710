import { FunctionComponent, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogTitle, DialogContent, Paper, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ButtonComponent from '../../../../components/ButtonComponent.js';
import { api_get_stopfiles } from '../../../../api/endpoint/stopfile/api_get_stopfiles';
import { api_delete_stopfile } from '../../../../api/endpoint/stopfile/api_delete_stopfile';
import { ApiObjStopfile } from '../../../../api/object/stopfile/ApiObjStopfile.js';
import dayjs from 'dayjs';
import { util_format_datetime } from '../../../../util/format/util_format';

export const StopfileList: FunctionComponent<{ search: string, selectedDate: dayjs.Dayjs | null }> = ({ search, selectedDate }) => {
  
    const [stopfileObjs, setStopfileObjs] = useState <Array<ApiObjStopfile>> ([]);
    const [deleteStopfileId, setDeleteStopfileId] = useState <number> (0);

    useEffect(() => {
        fetchStopfiles();
    }, []);
  
    const fetchStopfiles = async () => {
        api_get_stopfiles().then((resp) => {
            setStopfileObjs(resp);
        });
    }

    // ==========================================================
    // === Actions
    // ==========================================================

    const actionOpenDialog = (stopfileId: number) => {
        setDeleteStopfileId(stopfileId);
    }

    const actionDeleteStopfile = () => {
        api_delete_stopfile(deleteStopfileId)
            .then(() => {
                fetchStopfiles();
            })
            .catch(() => {
                console.error("Could not delete stopfile.");
            });
        setDeleteStopfileId(0);
    }

    const actionCloseDialog = () => {
        setDeleteStopfileId(0);
    }

    // ==========================================================
    // === Table data
    // ==========================================================

    const columns = [

        {
            field: 'name',
            headerName: 'Namn',
            width: 250,
            editable: false,
        },
        {
            field: 'date',
            headerName: 'Datum',
            width: 200,
            editable: false,
        },
        {
            field: 'count_objects',
            headerName: 'Objekt',
            width: 120,
            editable: false,
        },
        {
            field: 'count_matches',
            headerName: 'Matchningar',
            width: 120,
            editable: false,
        },
        {
            field: 'state',
            headerName: 'Status',
            width: 150,
            editable: false,
        },
        {
            field: 'delete',
            headerName: 'Val',
            width: 150,
            editable: false,
            renderCell: (params: any) => {
                const stopfileId = params.row.id;
                return (
                    <Button
                        onClick={() => {actionOpenDialog(stopfileId) }}
                        variant='outlined'
                        size={'small'}
                        startIcon={<DeleteIcon />}>
                        Radera
                    </Button>
                );
            }
        },
    ];

    const getRows = () => {
        const ret = [];

        const stateNames = [
            '0',
            'Påbörjad',
            'Bearbetar filen',
            'Klar',
            'Fel',
        ];

        const nameSearchList = [...stopfileObjs].filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
        const dateSearchList = nameSearchList.filter((item) => dayjs(item.created_at).format("DD/MM/YYYY") === dayjs(selectedDate).format("DD/MM/YYYY"))

        for (const item of selectedDate ? dateSearchList : nameSearchList) {

            let stateName = 'Okänd';
            if (stateNames[item.state] !== undefined) {
                stateName = stateNames[item.state];
            }

            ret.push({
                id: item.id,
                name: item.name,
                date: util_format_datetime(item.created_at),
                count_objects: item.item_count.total,
                count_matches: item.match_count.total,
                state: stateName,
                delete: item.id,
            });
        }
        return ret;
    }

    // ==========================================================
    // === Render
    // ==========================================================

    const tryCreateDialog = () => {
        if (deleteStopfileId === 0) {
            return undefined;
        }
        return (
            <Dialog open={true} onClose={() => actionCloseDialog()}>
                <DialogTitle>Radera stoppfil?</DialogTitle>
                <DialogContent>
                    <Box display={'flex'} flexDirection={'row'}>
                        <ButtonComponent title="Ja" fullWidth={false} disabled={false} onClick={() => {actionDeleteStopfile()}}></ButtonComponent>
                        <ButtonComponent title="Nej" fullWidth={false} disabled={false} onClick={() => {actionCloseDialog()}}></ButtonComponent>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <>
        <Paper style={{height: '670px'}}>
            <DataGrid
                rows={getRows()}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                checkboxSelection={false}
                isRowSelectable={() => {return false;}}
            />
            {tryCreateDialog()}
        </Paper>
        </>
    );
};

export class ApiObjExportField {

    // ====================================================
    // === Static
    // ====================================================

    static ID_1_BIRTH_DATE = 1;
    static ID_2_FIRST_NAME = 2;
    static ID_3_LAST_NAME = 3;
    static ID_4_ADDRESS_STREET = 4;
    static ID_5_ZIPCODE = 5;
    static ID_6_ADDRESS_CITY = 6;
    static ID_7_PHONE_MOBILE = 7;
    static ID_8_PHONE_FIXED = 8;
    static ID_9_AGE = 9;
    static ID_10_GENDER = 10;
    static ID_11_MOVED_AT_DATE = 11;
    static ID_12_COUNTRY = 12;
    static ID_13_RESIDENCE_AREA = 13;
    static ID_14_RESIDENCE_VALUE = 14;
    static ID_15_CONTACTS_ID = 15;
    static ID_16_ADDRESS_TYPE = 16;
    static ID_17_CAR_TYPE_NAME = 17;
    static ID_18_CAR_BRAND_NAME = 18;
    static ID_19_CAR_MODEL_NAME = 19;
    static ID_20_CAR_MODEL_YEAR = 20;
    static ID_21_ELNET_AREA = 21;
    static ID_22_ELNET_NET_ID = 22;
    static ID_23_ELNET_NET_OWNER_NAME = 23;
    static ID_24_PERSONAL_ID = 24;
    static ID_25_NIXED_MOBILE_NUMBER = 25;
    static ID_26_NIXED_FIXED_NUMBER = 26;
    static ID_27_PORTING_DATE_LAST = 27;
    static ID_28_OPERATOR_CURRENT = 28;
    static ID_29_OPERATOR_NET_CURRENT = 29;
    static ID_30_OPERATOR_PREVIOUS = 30;
    static ID_31_OPERATOR_NET_PREVIOUS = 31;
    static ID_32_PORTING_COUNT = 32;
    static ID_33_PORTING_DATE_FUTURE = 33;
    static ID_34_OPERATOR_FUTURE = 34;
    static ID_35_OPERATOR_NET_FUTURE = 35;
    static ID_36_FIBER_PROVIDER = 36;
    static ID_37_RESIDENCE_TYPE = 37;
    static ID_38_BRF_NAME = 38;
    static ID_39_FIBER_ACCESS_ID = 39;

    // ====================================================
    // === Fields
    // ====================================================

    id: number;
    name: string;
    is_premium: boolean;

    // ====================================================
    // === Create
    // ====================================================

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.name = data.name;
        this.is_premium = parseInt(data.is_premium) === 1;
    }
}
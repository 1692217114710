import { Box, Button, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FunctionComponent, useState, useEffect } from 'react';
import { ApiObjHitrateCustomField } from '../../../api/object/hitrate/ApiObjHitrateCustomField';
import { ApiObjExportField } from '../../../api/object/export/ApiObjExportField';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { UserManager } from '../../../data_layer/user/UserManager';
import { ErrorPageNoUser } from '../../error/ErrorPageNoUser';
import { ApiObjHitrate } from '../../../api/object/hitrate/ApiObjHitrate';
import { api_hitrate_list } from '../../../api/endpoint/hitrate/api_hitrate_list';
import { api_hitrate_patch } from '../../../api/endpoint/hitrate/api_hitrate_patch';
import { api_hitrate_custom_field_list } from '../../../api/endpoint/hitrate/api_hitrate_custom_field_list';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    userManager: UserManager
}

const STATE_1_LOADING_HITRATE_LIST = 1;
const STATE_2_WAITING_FOR_HITRATE_SELECT = 2;
const STATE_3_LOADING_CUSTOM_FIELDS = 3;
const STATE_4_IDLE = 4;
const STATE_5_SAVING = 5;
const STATE_6_SAVE_SUCCESS = 6;
const STATE_7_SAVE_FAIL = 7;

export const MyUserTabHitrate: FunctionComponent <Props> = ({
    baseData,
    userData,
    userManager,
}) => {

    // ======================================================================
    // === Not logged in
    // ======================================================================

    if (!userData.isLoggedIn()) {
        return <ErrorPageNoUser />;
    }

    // ======================================================================
    // === Settings
    // ======================================================================

    const STANDARD_EXPORT_FIELDS = [
        ApiObjExportField.ID_7_PHONE_MOBILE,
        ApiObjExportField.ID_8_PHONE_FIXED,
        ApiObjExportField.ID_2_FIRST_NAME,
        ApiObjExportField.ID_3_LAST_NAME,
        ApiObjExportField.ID_24_PERSONAL_ID,
        ApiObjExportField.ID_4_ADDRESS_STREET,
        ApiObjExportField.ID_5_ZIPCODE,
        ApiObjExportField.ID_6_ADDRESS_CITY,
        ApiObjExportField.ID_15_CONTACTS_ID,
    ];

    // ======================================================================
    // === State
    // ======================================================================

    const [state, setState] = useState <number> (STATE_1_LOADING_HITRATE_LIST);

    const [selectedHitrateId, setSelectedHitrateId] = useState <number> (0);
    const [hitrateList, setHitrateList] = useState <Array<ApiObjHitrate>> ([]);
    const [customFields, setCustomFields] = useState <Array<ApiObjHitrateCustomField>> ([]);
    const [formExportFieldsMap, setFormExportFieldsMap] = useState <Map<number, number>> (new Map());
    
    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {
        fetchHitrateList();
    }, []);

    const fetchHitrateList = async () => {
        api_hitrate_list()
            .then((result) => {
                setHitrateList(result);
                if (selectedHitrateId === 0) {
                    setState(STATE_2_WAITING_FOR_HITRATE_SELECT);
                } else {
                    setState(STATE_4_IDLE);
                }
            }).catch((err) => {
                console.error(err);
            });
    }

    const fetchCustomFields = async (newHitrateId: number) => {

        try {
            let hitrateObj = undefined;
            for (const item of hitrateList) {
                if (item.id === newHitrateId) {
                    hitrateObj = item;
                    break;
                }
            }
            if (hitrateObj === undefined) {
                return;
            }
            const map = new Map <number, number> ();
            setFormExportFieldsMap(map);

            setState(STATE_3_LOADING_CUSTOM_FIELDS);
            const result = await api_hitrate_custom_field_list(newHitrateId);
            setCustomFields(result);
            setState(STATE_4_IDLE);
            setupFormData(hitrateObj, result);
            
        } catch (err) {
            console.error(err);
        }
    }

    const setupFormData = (hitrateObj: ApiObjHitrate, newCustomFields: Array<ApiObjHitrateCustomField>) => {

        const map = new Map <number, number> ();
        for (const item of hitrateObj.custom_fields) {

            let found = false;
            for (const field of newCustomFields) {
                if (field.id === item.custom_field_id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                continue;
            }
            map.set(item.export_field_id, item.custom_field_id);
        }
        setFormExportFieldsMap(map);
    }

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionSelectHitrateInstance = (event: SelectChangeEvent<number>) => {
        const newValue = event.target.value;
        if (typeof newValue !== 'number') {
            return;
        }
        setSelectedHitrateId(newValue);
        fetchCustomFields(newValue);
    }

    const actionSelectCustomField = (exportFieldId : number, event: SelectChangeEvent<number>) => {
        const newValue = event.target.value;
        if (typeof newValue !== 'number') {
            return;
        }
        const map = new Map(formExportFieldsMap);
        map.set(exportFieldId, newValue);
        setFormExportFieldsMap(map);
    }

    const actionSave = () => {

        const validStates = [
            STATE_4_IDLE,
            STATE_6_SAVE_SUCCESS,
            STATE_7_SAVE_FAIL
        ];
        if (!validStates.includes(state)) {
            return;
        }

        setState(STATE_5_SAVING);

        const tempCustomFields = [];

        for (const exportField of userData.getExportFields()) {
            const customFieldId = formExportFieldsMap.get(exportField.id);
            if (customFieldId !== undefined && customFieldId !== 0) {
                tempCustomFields.push({
                    export_field_id: exportField.id,
                    custom_field_id: customFieldId
                });
            }
        }

        if (selectedHitrateId === 0) {
            return;
        }

        api_hitrate_patch(selectedHitrateId, tempCustomFields)
            .then((result) => {
                fetchHitrateList();
                setState(STATE_6_SAVE_SUCCESS);
            }).catch((err) => {
                console.error(err);
                setState(STATE_7_SAVE_FAIL);
            });
    }

    // ======================================================================
    // === Helpers
    // ======================================================================

    const isFormActive = () : boolean => {
        const validStates = [
            STATE_2_WAITING_FOR_HITRATE_SELECT,
            STATE_4_IDLE,
            STATE_6_SAVE_SUCCESS,
            STATE_7_SAVE_FAIL
        ];
        return validStates.includes(state);
    }

    // ======================================================================
    // === Errors
    // ======================================================================

    const isErrorExportField = (exportFieldId: number) : boolean => {

        const customFieldId = formExportFieldsMap.get(exportFieldId);
        if (customFieldId === undefined || customFieldId === 0) {
            return false;
        }
        for (const item of userData.getExportFields()) {
            if (item.id === exportFieldId) {
                continue;
            }
            const tempId = formExportFieldsMap.get(item.id);
            if (tempId === undefined) {
                continue;
            }
            if (tempId === customFieldId) {
                return true;
            }
        }
        return false;
    }

    const isErrorAnyField = () : boolean => {
        for (const item of userData.getExportFields()) {
            if (isErrorExportField(item.id)) {
                return true;
            }
        }
        return false;
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderHitrateSelect = () => {

        const menuItems = [];
        menuItems.push(
            <MenuItem
                key={0}
                value={0}>
                    {'- Välj -'}
            </MenuItem>
        );
        for (const item of hitrateList) {
            menuItems.push(
                <MenuItem
                    key={item.id}
                    value={item.id}>
                        {item.public_name}
                </MenuItem>
            );
        }

        return (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Box width={'150px'}>
                    {'Hitrate instans: '}
                </Box>
                <Box width={'300px'}>
                    <Select
                        style={{width: '280px'}}
                        size={'small'}
                        value={selectedHitrateId}
                        onChange={(event) => {actionSelectHitrateInstance(event)}}
                        disabled={!isFormActive()}>

                        {menuItems}

                    </Select>
                </Box>
            </Box>
        );
    }

    const renderFormItems = () : JSX.Element|undefined => {

        if (selectedHitrateId === 0) {
            return undefined;
        }

        const formElements : Array<JSX.Element> = [];
        for (const item of userData.getExportFields()) {
            formElements.push(renderFormItem(item));
        }

        return (
            <Box paddingTop={'26px'} display={'flex'} flexDirection={'column'} gap={'8px'}>
                <Box key={0} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box fontWeight={'bold'} width={'230px'}>
                        Exportfält
                    </Box>
                    <Box fontWeight={'bold'} width={'300px'}>
                        Kundkortsfält
                    </Box>
                </Box>
                {formElements}
            </Box>
        );
    }

    const renderFormItem = (exportField: ApiObjExportField) : JSX.Element => {

        const menuItems = [];
        menuItems.push(
            <MenuItem
                key={0}
                value={0}>
                    {'-'}
            </MenuItem>
        );
        for (const item of customFields) {
            menuItems.push(
                <MenuItem
                    key={item.id}
                    value={item.id}>
                        {item.name}
                </MenuItem>
            );
        }

        let selectValue = formExportFieldsMap.get(exportField.id);
        if (selectValue === undefined) {
            selectValue = 0;
        }

        let selectElement = (
            <Select
                style={{width: '280px'}}
                size={'small'}
                value={selectValue}
                onChange={(event) => {actionSelectCustomField(exportField.id, event)}}
                error={isErrorExportField(exportField.id)}
                disabled={!isFormActive()}>

                {menuItems}

            </Select>
        );
        if (STANDARD_EXPORT_FIELDS.includes(exportField.id)) {
            selectElement = (
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} height={'40px'}>Standardfält</Box>
            );
        }

        return (
            <Box key={exportField.id} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Box width={'230px'}>
                    {exportField.name}
                </Box>
                <Box width={'300px'}>
                    {selectElement}
                </Box>
            </Box>
        );
    }

    const renderSaveButton = () : JSX.Element|undefined => {
        if (selectedHitrateId === 0) {
            return undefined;
        }
        return (
            <Box display={'flex'} flexDirection={'row'} gap={'10px'} paddingTop={'20px'} alignItems={'center'}>
                <Button variant='contained' color='secondary' onClick={actionSave} disabled={!isFormActive() || isErrorAnyField()}>
                    Spara
                </Button>
                {renderStatus()}
            </Box>
        );
    }

    const renderStatus = () : JSX.Element|undefined => {

        if (isErrorAnyField()) {
            return (
                <Box color={'#FF0000'}>Dubletter existerar</Box>
            );

        } else if (state === STATE_5_SAVING) {
            return (
                <Box>
                    Sparar...
                </Box>
            );

        } else if (state === STATE_7_SAVE_FAIL) {
            return (
                <Box color={'#FF0000'}>
                    Ett fel uppstod.
                </Box>
            );

        } else {
            return undefined
        }
    }

    // ======================================================================
    // === Render Main
    // ======================================================================

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            <Box fontSize={'20px'} paddingBottom={'16px'}>Mappning av kundkortsfält</Box>
            {renderHitrateSelect()}
            {renderFormItems()}
            {renderSaveButton()}
        </Box>
    );
}

import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjHitrate } from '../../object/hitrate/ApiObjHitrate';

export const api_hitrate_list = async () : Promise<Array<ApiObjHitrate>> => {

    const url = API_URL+'/hitrate';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };

    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjHitrate(item));
    }
    return ret;
}

import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export type HitrateContactListCreateResult = {
    export_person_count: number,
    valid_person_count: number,
    failed_person_count: number
}

export const api_hitrate_contact_list_create = async (hitrateId: number, exportId: number, campaignId: number) : Promise<HitrateContactListCreateResult> => {

    const url = API_URL+'/hitrate/'+hitrateId+'/contact_list';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };

    const body = {
        export_id: exportId,
        campaign_id: campaignId
    }

    const resp = await Axios.post(
        url,
        body,
        config
    );


    return {
        export_person_count: parseInt(resp.data.export_person_count),
        valid_person_count: parseInt(resp.data.valid_person_count),
        failed_person_count: parseInt(resp.data.failed_person_count)
    };
}

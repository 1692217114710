import { Box, Button, Checkbox, TextField } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjAdminBaseData } from '../../../api/object/admin/ApiObjAdminBaseData';
import { ApiObjAdminUserHitrateInstance } from '../../../api/object/admin/ApiObjAdminUserHitrateInstance';
import { api_admin_user_hitrate_instance_create } from '../../../api/endpoint/admin/user/hitrate_instance/api_admin_user_hitrate_instance_create';
import { api_admin_user_hitrate_instance_patch } from '../../../api/endpoint/admin/user/hitrate_instance/api_admin_user_hitrate_instance_patch';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    adminBaseData: ApiObjAdminBaseData
    hitrateInstance: ApiObjAdminUserHitrateInstance|undefined
    otherUserId: number
    callbackCloseAndReload: () => void
}

export const AdminUserTabHitrateDetails: FunctionComponent <Props> = ({
    baseData,
    userData,
    adminBaseData,
    hitrateInstance,
    otherUserId,
    callbackCloseAndReload
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [isSaving, setIsSaving] = useState <boolean> (false);
    const [isError, setIsError] = useState <boolean> (false);
    
    const [formPublicName, setFormPublicName] = useState <string> ('');
    const [formUrl, setFormUrl] = useState <string> ('');
    const [formApiToken, setFormApiToken] = useState <string> ('');
    const [formIsActive, setFormIsActive] = useState <boolean> (true);
    
    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {
        
        if (hitrateInstance !== undefined) {
            setFormPublicName(hitrateInstance.public_name);
            setFormUrl(hitrateInstance.url);
            setFormApiToken(hitrateInstance.api_token);
            setFormIsActive(hitrateInstance.is_active);
        } else {
            setFormPublicName('');
            setFormUrl('');
            setFormApiToken('');
            setFormIsActive(true);
        }

    }, [hitrateInstance]);

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionSave = () => {

        setIsSaving(true);
        setIsError(false);

        const data = {
            is_active: formIsActive,
            public_name: formPublicName,
            url: formUrl,
            api_token: formApiToken
        };

        if (hitrateInstance === undefined) {
            api_admin_user_hitrate_instance_create(otherUserId, data)
                .then(() => {
                    callbackCloseAndReload();
                    setIsSaving(false);
                }).catch((err) => {
                    console.error(err);
                    setIsSaving(false);
                    setIsError(true);
                });
        } else {
            api_admin_user_hitrate_instance_patch(otherUserId, hitrateInstance.id, data)
                .then(() => {
                    callbackCloseAndReload();
                    setIsSaving(false);
                }).catch((err) => {
                    console.error(err);
                    setIsSaving(false);
                    setIsError(true);
                });
        }
    }

    // ======================================================================
    // === Errors
    // ======================================================================

    const getErrorPublicName = () : string|undefined => {
        if (formPublicName.length === 0) {
            return "Minst en bokstav";
        } else if (formPublicName.length > 50) {
            return "För långt.";
        }
        return undefined;
    }
    const getErrorUrl = () : string|undefined => {
        
        if (formUrl.length < 6) {
            return "För kort";
        } else if (formUrl.length > 100) {
            return "För lång";
        } else if (formUrl.substring(0, 8) !== 'https://') {
            return 'Måste börja med https://';
        }
        return undefined;
    }

    const getErrorApiToken = () : string|undefined => {
        if (formApiToken.length < 6) {
            return "För kort";
        } else if (formApiToken.length > 100) {
            return "För lång";
        }
        return undefined;
    }

    // ======================================================================
    // === Checks
    // ======================================================================

    const isAllValuesValid  = () => {

        const allCheckerFunctions = [
            getErrorPublicName,
            getErrorUrl,
            getErrorApiToken
        ];

        for (const item of allCheckerFunctions) {
            if (item() !== undefined) {
                return false;
            }
        }
        return true;
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const styleFormItemBox = {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
        alignItems: 'start',
        gap: '12px'
    } as React.CSSProperties;

    const renderTitle = () : JSX.Element => {
        if (hitrateInstance === undefined) {
            return (
                <Box fontSize={'20px'}>Lägg till Hitrate instans</Box>
            );
        } else {
            return (
                <Box fontSize={'20px'}>Redigera Hitrate instans</Box>
            );
        }
        
    }

    const renderItemPublicName = () : JSX.Element => {

        const errorText = getErrorPublicName();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Namn, visas för användaren'}
                    required
                    disabled={isSaving}
                    value={formPublicName}
                    onChange={(e) => {
                        setFormPublicName(e.target.value)
                    }}
                    error={errorText !== undefined}
                    helperText={errorText}
                    style = {{width: '300px'}}
                />
            </Box>
        );
    }

    const renderItemUrl = () : JSX.Element => {

        const errorText = getErrorUrl();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Url'}
                    required
                    disabled={isSaving}
                    value={formUrl}
                    onChange={(e) => {
                        setFormUrl(e.target.value)
                    }}
                    error={errorText !== undefined}
                    helperText={errorText}
                    style = {{width: '400px'}}
                />
            </Box>
        );
    }

    const renderItemApiToken = () : JSX.Element => {

        const errorText = getErrorApiToken();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Api Token'}
                    required
                    disabled={isSaving}
                    value={formApiToken}
                    onChange={(e) => {
                        setFormApiToken(e.target.value)
                    }}
                    error={errorText !== undefined}
                    helperText={errorText}
                    style = {{width: '400px'}}
                />
            </Box>
        );
    }

    const renderItemIsActive = () : JSX.Element => {
        return (
            <Box style={styleFormItemBox}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box>
                        <Checkbox
                            checked={formIsActive}
                            disabled={isSaving}
                            color={'secondary'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFormIsActive(event.target.checked);
                            }}
                        />
                    </Box>
                    <Box>
                        Aktiv
                    </Box>
                </Box>
            </Box>
        );
    }

    const renderStatus = () : JSX.Element|undefined => {

        if (isSaving) {
            return (
                <Box style={styleFormItemBox}>
                    Sparar...
                </Box>
            );
        } else if (isError) {
            return (
                <Box color={'#FF0000'} style={styleFormItemBox}>
                    Ett fel uppstod.
                </Box>
            );
        } else {
            return undefined
        }
    }

    return (
        <Box paddingTop={'30px'} display={'flex'} flexDirection={'column'}>
            {renderTitle()}
            {renderItemPublicName()}
            {renderItemUrl()}
            {renderItemApiToken()}
            {renderItemIsActive()}
            <Box display={'flex'} flexDirection={'row'} gap={'10px'} paddingTop={'20px'}>
                <Button variant='contained' color='secondary' onClick={actionSave} disabled={isSaving || !isAllValuesValid()}>
                    Spara
                </Button>
            </Box>
            {renderStatus()}
        </Box>
    );
}

import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjAdminBaseData } from '../../../api/object/admin/ApiObjAdminBaseData';
import { ApiObjAdminUser } from '../../../api/object/admin/ApiObjAdminUser';
import { util_validate_email } from '../../../util/validation/util_validate';
import { AdminUserDetailsParams, api_admin_user_save_details } from '../../../api/endpoint/admin/user/details/api_admin_user_save_details';
import { ApiObjUserMy } from '../../../api/object/user/ApiObjUserMy';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    adminBaseData: ApiObjAdminBaseData
    otherUser: ApiObjAdminUser
    parentReloadUser: () => void
}

export const AdminUserTabUserDetails: FunctionComponent <Props> = ({
    baseData,
    userData,
    adminBaseData,
    otherUser,
    parentReloadUser
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [userState, setUserState] = useState <number> (otherUser.state);

    const [isFacebookEnabled, setIsFacebookEnabled] = useState <boolean> (otherUser.is_facebook_enabled);
    const [isHitrateEnabled, setIsHitrateEnabled] = useState <boolean> (otherUser.is_hitrate_enabled);

    const [firstName, setFirstName] = useState <string> (otherUser.first_name);
    const [lastName, setLastName] = useState <string> (otherUser.last_name);
    const [email, setEmail] = useState <string> (otherUser.email);
    const [companyName, setCompanyName] = useState <string> (otherUser.company_name);
    const [companyOrgNumber, setCompanyOrgNumber] = useState <string> (otherUser.company_org_number);

    const [address, setAddress] = useState <string> (otherUser.address);
    const [zipcode, setZipcode] = useState <string> (otherUser.zipcode);
    const [city, setCity] = useState <string> (otherUser.city);
    const [phoneNumber, setPhoneNumber] = useState <string> (otherUser.phone_number);
    
    const [isInvoiceEnabled, setIsInvoiceEnabled] = useState <boolean> (otherUser.is_invoice_enabled);
    const [exportSubscriptionType, setExportSubscriptionType] = useState <number> (otherUser.export_subscription.type);
    const [exportSubscriptionPrice, setExportSubscriptionPrice] = useState <string> (otherUser.export_subscription.price + '');
    const [exportSubscriptionLeadsMax, setExportSubscriptionLeadsMax] = useState <string> (otherUser.export_subscription.leads_max + '');
    const [exportSubscriptionLeads, setExportSubscriptionLeads] = useState <string> (otherUser.export_subscription.leads + '');

    const [populateFileSubscriptionType, setPopulateFileSubscriptionType] = useState <number> (otherUser.populate_file_subscription.type);
    const [populateFileSubscriptionPrice, setPopulateFileSubscriptionPrice] = useState <string> (otherUser.populate_file_subscription.price + '');
    const [populateFileSubscriptionSpendingMax, setPopulateFileSubscriptionSpendingMax] = useState <string> (otherUser.populate_file_subscription.spending_max + '');
    const [populateFileSubscriptionSpending, setPopulateFileSubscriptionSpending] = useState <string> (otherUser.populate_file_subscription.spending + '');

    const [monthlyQuota, setMonthlyQuota] = useState <string> (otherUser.api_monthly_quota + '');

    const [isSaving, setIsSaving] = useState <boolean> (false);

    // ======================================================================
    // === Set form data
    // ======================================================================

    useEffect(() => {
        setUserState(otherUser.state);
        setIsFacebookEnabled(otherUser.is_facebook_enabled);
        setIsHitrateEnabled(otherUser.is_hitrate_enabled);
        setFirstName(otherUser.first_name);
        setLastName(otherUser.last_name);
        setEmail(otherUser.email);
        setCompanyName(otherUser.company_name);
        setCompanyOrgNumber(otherUser.company_org_number);
        setAddress(otherUser.address);
        setZipcode(otherUser.zipcode);
        setCity(otherUser.city);
        setPhoneNumber(otherUser.phone_number);
        setIsInvoiceEnabled(otherUser.is_invoice_enabled);
        setExportSubscriptionType(otherUser.export_subscription.type);
        setExportSubscriptionPrice(otherUser.export_subscription.price + '');
        setExportSubscriptionLeadsMax(otherUser.export_subscription.leads_max + '');
        setExportSubscriptionLeads(otherUser.export_subscription.leads + '');
        setMonthlyQuota(otherUser.api_monthly_quota + '');
        setPopulateFileSubscriptionType(otherUser.populate_file_subscription.type);
        setPopulateFileSubscriptionPrice(otherUser.populate_file_subscription.price + '');
        setPopulateFileSubscriptionSpendingMax(otherUser.populate_file_subscription.spending_max + '');
        setPopulateFileSubscriptionSpending(otherUser.populate_file_subscription.spending + '');
    }, [otherUser]);
    
    // ======================================================================
    // === Actions
    // ======================================================================
  
    const actionSave = () => {

        setIsSaving(true);

        const data : AdminUserDetailsParams = {
            state: userState,
            is_facebook_enabled: isFacebookEnabled,
            is_hitrate_enabled: isHitrateEnabled,
            first_name: firstName,
            last_name: lastName,
            email: email,
            company_name: companyName,
            company_org_number: companyOrgNumber,
            address: address,
            zipcode: zipcode,
            city: city,
            phone_number: phoneNumber,
            api_monthly_quota: parseInt(monthlyQuota),
            is_invoice_enabled: isInvoiceEnabled,
            export_subscription_type: exportSubscriptionType,
            export_subscription_price: parseInt(exportSubscriptionPrice),
            export_subscription_leads: parseInt(exportSubscriptionLeads),
            export_subscription_leads_max: parseInt(exportSubscriptionLeadsMax),
            populate_file_subscription_type: populateFileSubscriptionType,
            populate_file_subscription_price: parseInt(populateFileSubscriptionPrice),
            populate_file_subscription_spending: parseInt(populateFileSubscriptionSpending),
            populate_file_subscription_spending_max: parseInt(populateFileSubscriptionSpendingMax)
        };

        api_admin_user_save_details(otherUser.id, data)
            .then(() => {
                setIsSaving(false);
                parentReloadUser();
            }).catch((err) => {
                console.error("Could not save user info.");
                // Not setting isSaving to false to make obvious something went wrong.
            });
    }

    // ======================================================================
    // === Field Validation
    // ======================================================================

    const getErrorEmail = () => {
        if (email.length === 0) {
            return "Epostadress krävs";
        }
        if (!util_validate_email(email)) {
            return "Felaktig epostadress";
        }
        return undefined;
    }

    const getErrorPhoneNumber = () => {
        if (phoneNumber.length === 0) {
            return "Telefonnummer krävs";
        } else if (phoneNumber.length < 8) {
            return "Telefonnummer för kort";
        } else if (phoneNumber.length > 14) {
            return "Telefonnummer för långt";
        }
        return undefined;
    }

    const getErrorFirstName = () => {
        if (firstName.length > 50) {
            return "Förnamn är för långt";
        }
        return undefined;
    }

    const getErrorLastName = () => {
        if (lastName.length > 50) {
            return "Efternamn är för långt";
        }
        return undefined;
    }

    const getErrorAddress = () => {
        if (address.length > 100) {
            return "Adress är för lång";
        }
        return undefined;
    }

    const getErrorZipcode = () => {
        if (zipcode.length > 0) {
            const zipCodeInt = parseInt(zipcode);
            const isValid = zipcode.length === 5
                && !isNaN(zipCodeInt)
                && zipCodeInt >= 10000
                && zipCodeInt <= 99999;
            if (!isValid) {
                return "Ogiltigt postnummer";
            }
        }
        return undefined;
    }

    const getErrorCity = () => {
        if (city.length > 100) {
            return "Namn är för långt.";
        }
        return undefined;
    }

    const getErrorCompanyName = () => {
        if (companyName.length > 50) {
            return "Företagsnamn är för långt."
        }
        return undefined;
    }

    const getErrorCompanyOrgNumber = () => {
        if (companyOrgNumber.length > 50) {
            return "Organisationsnummer är för långt";
        }
        return undefined;
    }

    const getErrorExportSubscriptionType = () => {
        const validTypes = [
            ApiObjUserMy.EXPORT_SUBSCRIPTION_TYPE_NONE,
            ApiObjUserMy.EXPORT_SUBSCRIPTION_TYPE_MONTHLY,
            ApiObjUserMy.EXPORT_SUBSCRIPTION_TYPE_YEARLY,
            ApiObjUserMy.EXPORT_SUBSCRIPTION_TYPE_QUARTERLY,
        ];
        if (!validTypes.includes(exportSubscriptionType)) {
            return "Ogiltigt värde";
        }
        return undefined;
    }

    const getErrorExportSubscriptionPrice = () => {
        const priceInt = parseInt(exportSubscriptionPrice);
        if (isNaN(priceInt) || priceInt < 0 || priceInt > 10000000) {
            return "Ogiltigt pris";
        }
        return undefined;
    }

    const getErrorExportSubscriptionLeadsMax = () => {
        const maxLeadsInt = parseInt(exportSubscriptionLeadsMax);
        if (isNaN(maxLeadsInt) || maxLeadsInt < 0 || maxLeadsInt > 100000000) {
            return "Ogiltigt värde";
        }
        return undefined;
    }

    const getErrorExportSubscriptionLeads = () => {
        const totalLeadsInt = parseInt(exportSubscriptionLeads);
        if (isNaN(totalLeadsInt) || totalLeadsInt < 0 || totalLeadsInt > 100000000) {
            return "Ogiltigt värde";
        }
        return undefined;
    }

    const getErrorPopulateFileSubscriptionType = () => {
        const validTypes = [
            ApiObjUserMy.POPULATE_FILE_SUBSCRIPTION_TYPE_NONE,
            ApiObjUserMy.POPULATE_FILE_SUBSCRIPTION_TYPE_MONTHLY,
            ApiObjUserMy.POPULATE_FILE_SUBSCRIPTION_TYPE_YEARLY,
            ApiObjUserMy.POPULATE_FILE_SUBSCRIPTION_TYPE_QUARTERLY,
        ];
        if (!validTypes.includes(exportSubscriptionType)) {
            return "Ogiltigt värde";
        }
        return undefined;
    }

    const getErrorPopulateFileSubscriptionPrice = () => {
        const priceInt = parseInt(populateFileSubscriptionPrice);
        if (isNaN(priceInt) || priceInt < 0 || priceInt > 10000000) {
            return "Ogiltigt pris";
        }
        return undefined;
    }

    const getErrorPopulateFileSubscriptionSpendingMax = () => {
        const maxLeadsInt = parseInt(populateFileSubscriptionSpendingMax);
        if (isNaN(maxLeadsInt) || maxLeadsInt < 0 || maxLeadsInt > 100000000) {
            return "Ogiltigt värde";
        }
        return undefined;
    }

    const getErrorPopulateFileSubscriptionSpending = () => {
        const totalLeadsInt = parseInt(populateFileSubscriptionSpending);
        if (isNaN(totalLeadsInt) || totalLeadsInt < 0 || totalLeadsInt > 100000000) {
            return "Ogiltigt värde";
        }
        return undefined;
    }

    const getErrorMonthlyQuota = () => {
        const monthlyQuotaInt = parseInt(monthlyQuota);
        if (isNaN(monthlyQuotaInt) || monthlyQuotaInt < 0 || monthlyQuotaInt > 100000000) {
            return "Ogiltigt värde";
        }
        return undefined;
    }

    // ======================================================================
    // === Checks
    // ======================================================================

    const isAllValuesValid  = () => {

        const allCheckerFunctions = [
            getErrorEmail,
            getErrorPhoneNumber,
            getErrorFirstName,
            getErrorLastName,
            getErrorAddress,
            getErrorZipcode,
            getErrorCity,
            getErrorCompanyName,
            getErrorCompanyOrgNumber,
            getErrorExportSubscriptionType,
            getErrorExportSubscriptionPrice,
            getErrorExportSubscriptionLeadsMax,
            getErrorExportSubscriptionLeads,
            getErrorPopulateFileSubscriptionType,
            getErrorPopulateFileSubscriptionPrice,
            getErrorPopulateFileSubscriptionSpendingMax,
            getErrorPopulateFileSubscriptionSpending,
            getErrorMonthlyQuota
        ];

        for (const item of allCheckerFunctions) {
            if (item() !== undefined) {
                return false;
            }
        }
        return true;
    }

    // ======================================================================
    // === Render elements
    // ======================================================================

    const styleFormItemBox = {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
        alignItems: 'start',
        gap: '12px'
    } as React.CSSProperties;

    const renderDivider = (name: string) : JSX.Element => {
        return (
            <Box paddingTop={'15px'} paddingBottom={'5px'} fontSize={'18px'} fontWeight={'bold'}>
                {name}
            </Box>
        );
    }

    const renderUserState = () : JSX.Element => {

        const subscriptionSelectElements : Array<JSX.Element> = [];
        const options = [
            {name: 'Ny användare', value: ApiObjAdminUser.STATE_1_NEW},
            {name: 'Aktiv', value: ApiObjAdminUser.STATE_2_ACTIVE},
            {name: 'Avstängd', value: ApiObjAdminUser.STATE_3_SUSPENDED},
            {name: 'Borttagen', value: ApiObjAdminUser.STATE_4_DELETED},
        ];
        for (const item of options) {
            subscriptionSelectElements.push(
                <MenuItem
                    key={item.value}
                    value={item.value}>
                        {item.name}
                </MenuItem>
            );
        }

        return (
            <Box style={styleFormItemBox}>
                <Select
                    labelId="subscription_type_label"
                    variant="outlined"
                    size={'medium'}
                    disabled={isSaving}
                    value={userState}
                    style = {{width: '200px'}}
                    onChange={(event) => {
                            if (typeof event.target.value === 'number') {
                                setUserState(event.target.value);
                            } else {
                                setUserState(parseInt(event.target.value))
                            }
                        }}
                    >
                        {subscriptionSelectElements}
                </Select>
        </Box>
            
        );
    }

    const renderPermissions = () : JSX.Element => {
        return (
            <Box style={styleFormItemBox}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box>
                        <Checkbox
                            checked={isFacebookEnabled}
                            disabled={isSaving}
                            color={'secondary'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setIsFacebookEnabled(event.target.checked);
                            }}
                        />
                    </Box>
                    <Box>
                        Facebook
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box>
                        <Checkbox
                            checked={isHitrateEnabled}
                            disabled={isSaving}
                            color={'secondary'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setIsHitrateEnabled(event.target.checked);
                            }}
                        />
                    </Box>
                    <Box>
                        Hitrate
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} marginLeft={'14px'}>
                    <Box>
                        <Checkbox
                            checked={otherUser.is_admin}
                            disabled={true}
                            color={'secondary'}
                        />
                    </Box>
                    <Box>
                        Admin
                    </Box>
                    <Box marginLeft={'10px'} color={'#AAAAAA'}>
                        (Måste sättas i databasen)
                    </Box>
                </Box>
            </Box>
        );
    }

    const renderEmail = () : JSX.Element => {

        const errorText = getErrorEmail();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Epost'}
                    required
                    disabled={isSaving}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    error={errorText !== undefined}
                    helperText={errorText}
                    style = {{width: '450px'}}
                />
            </Box>
        );
    }

    const renderPhoneNumber = () : JSX.Element => {

        const errorText = getErrorPhoneNumber();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Telefonnummer'}
                    required
                    disabled={isSaving}
                    value={phoneNumber}
                    onChange={(e) => {
                        setPhoneNumber(e.target.value)
                    }}
                    error={errorText !== undefined}
                    helperText={errorText}
                    style = {{width: '200px'}}
                />
            </Box>
        );
    }

    const renderName = () : JSX.Element => {

        const errorTextFirst = getErrorFirstName();
        const errorTextLast = getErrorLastName();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Förnamn'}
                    disabled={isSaving}
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value)
                    }}
                    error={errorTextFirst !== undefined}
                    helperText={errorTextFirst}
                    style = {{width: '300px'}}
                />
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Efternamn'}
                    disabled={isSaving}
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value)
                    }}
                    error={errorTextLast !== undefined}
                    helperText={errorTextLast}
                    style = {{width: '300px'}}
                />
            </Box>
        );
    }

    const renderAddress = () : JSX.Element => {

        const errorText = getErrorAddress();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Adress'}
                    disabled={isSaving}
                    value={address}
                    onChange={(e) => {
                        setAddress(e.target.value)
                    }}
                    error={errorText !== undefined}
                    helperText={errorText}
                    style = {{width: '300px'}}
                />
            </Box>
        );
    }

    const renderZipAndCity = () : JSX.Element => {

        const errorTextZip = getErrorZipcode();
        const errorTextCity = getErrorCity();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Postnummer'}
                    disabled={isSaving}
                    value={zipcode}
                    onChange={(e) => {
                        setZipcode(e.target.value)
                    }}
                    error={errorTextZip !== undefined}
                    helperText={errorTextZip}
                    style = {{width: '150px'}}
                />
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Ort'}
                    disabled={isSaving}
                    value={city}
                    onChange={(e) => {
                        setCity(e.target.value)
                    }}
                    error={errorTextCity !== undefined}
                    helperText={errorTextCity}
                    style = {{width: '300px'}}
                />
            </Box>
        );
    }

    const renderCompany = () : JSX.Element => {

        const errorTextName = getErrorCompanyName();
        const errorTextNumber = getErrorCompanyOrgNumber();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Företagsnamn'}
                    disabled={isSaving}
                    value={companyName}
                    onChange={(e) => {
                        setCompanyName(e.target.value)
                    }}
                    error={errorTextName !== undefined}
                    helperText={errorTextName}
                    style = {{width: '300px'}}
                />
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Organisationsnummer'}
                    disabled={isSaving}
                    value={companyOrgNumber}
                    onChange={(e) => {
                        setCompanyOrgNumber(e.target.value)
                    }}
                    error={errorTextNumber !== undefined}
                    helperText={errorTextNumber}
                    style = {{width: '200px'}}
                />
            </Box>
        );
    }

    const renderExportSubscription1 = () : JSX.Element => {

        const errorTextType = getErrorExportSubscriptionType();
        const errorTextPrice = getErrorExportSubscriptionPrice();

        const subscriptionSelectElements : Array<JSX.Element> = [];
        const options = [
            {name: 'Ingen', value: 0},
            {name: 'Månadsvis', value: 1},
            {name: 'Kvartalsvis', value: 3},
            {name: 'Årsvis', value: 2},
        ];
        for (const item of options) {
            subscriptionSelectElements.push(
                <MenuItem
                    key={item.value}
                    value={item.value}>
                        {item.name}
                </MenuItem>
            );
        }

        return (
            <Box style={styleFormItemBox}>
                <FormControl>
                    <InputLabel id="subscription_type_label">Prenumeration</InputLabel>
                    <Select
                        labelId="subscription_type_label"
                        variant="outlined"
                        size={'medium'}
                        label={'Prenumeration'}
                        disabled={isSaving}
                        value={exportSubscriptionType}
                        error={errorTextType !== undefined}
                        style = {{width: '200px'}}
                        onChange={(event) => {
                                if (typeof event.target.value === 'number') {
                                    setExportSubscriptionType(event.target.value);
                                } else {
                                    setExportSubscriptionType(parseInt(event.target.value))
                                }
                            }}
                        >
                            {subscriptionSelectElements}
                    </Select>
                </FormControl>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Pris på prenumeration'}
                    disabled={isSaving}
                    value={exportSubscriptionPrice}
                    onChange={(e) => {
                        setExportSubscriptionPrice(e.target.value)
                    }}
                    error={errorTextPrice !== undefined}
                    helperText={errorTextPrice}
                    style = {{width: '200px'}}
                />
            </Box>
        );
    }

    const renderExportSubscription2 = () => {

        const errorTextMax = getErrorExportSubscriptionLeadsMax();
        const errorTextTotal = getErrorExportSubscriptionLeads();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Max antal leads'}
                    disabled={isSaving}
                    value={exportSubscriptionLeadsMax}
                    onChange={(e) => {
                        setExportSubscriptionLeadsMax(e.target.value)
                    }}
                    error={errorTextMax !== undefined}
                    helperText={errorTextMax}
                    style = {{width: '200px'}}
                />
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Beställda leads'}
                    disabled={isSaving}
                    value={exportSubscriptionLeads}
                    onChange={(e) => {
                        setExportSubscriptionLeads(e.target.value)
                    }}
                    error={errorTextTotal !== undefined}
                    helperText={errorTextTotal}
                    style = {{width: '200px'}}
                />
            </Box>
        );
    }

    const renderInvoice = () : JSX.Element => {
        return (
            <Box style={styleFormItemBox}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box>
                        <Checkbox
                            checked={isInvoiceEnabled}
                            disabled={isSaving}
                            color={'secondary'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setIsInvoiceEnabled(event.target.checked);
                            }}
                        />
                    </Box>
                    <Box>
                        Faktura används
                    </Box>
                </Box>
            </Box>
        );
    }
    
    const renderPopulateFileSubscription1 = () : JSX.Element => {

        const errorTextType = getErrorPopulateFileSubscriptionType();
        const errorTextPrice = getErrorPopulateFileSubscriptionPrice();

        const subscriptionSelectElements : Array<JSX.Element> = [];
        const options = [
            {name: 'Ingen', value: 0},
            {name: 'Månadsvis', value: 1},
            {name: 'Kvartalsvis', value: 3},
            {name: 'Årsvis', value: 2},
        ];
        for (const item of options) {
            subscriptionSelectElements.push(
                <MenuItem
                    key={item.value}
                    value={item.value}>
                        {item.name}
                </MenuItem>
            );
        }

        return (
            <Box style={styleFormItemBox}>
                <FormControl>
                    <InputLabel id="populate_subscription_type_label">Prenumeration</InputLabel>
                    <Select
                        labelId="populate_subscription_type_label"
                        variant="outlined"
                        size={'medium'}
                        label={'Prenumeration'}
                        disabled={isSaving}
                        value={populateFileSubscriptionType}
                        error={errorTextType !== undefined}
                        style = {{width: '200px'}}
                        onChange={(event) => {
                                if (typeof event.target.value === 'number') {
                                    setPopulateFileSubscriptionType(event.target.value);
                                } else {
                                    setPopulateFileSubscriptionType(parseInt(event.target.value))
                                }
                            }}
                        >
                            {subscriptionSelectElements}
                    </Select>
                </FormControl>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Pris på prenumeration'}
                    disabled={isSaving}
                    value={populateFileSubscriptionPrice}
                    onChange={(e) => {
                        setPopulateFileSubscriptionPrice(e.target.value)
                    }}
                    error={errorTextPrice !== undefined}
                    helperText={errorTextPrice}
                    style = {{width: '200px'}}
                />
            </Box>
        );
    }

    const renderPopulateFileSubscription2 = () => {
        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Nummersättningspott'}
                    disabled={isSaving}
                    value={populateFileSubscriptionSpendingMax}
                    onChange={(e) => {
                        setPopulateFileSubscriptionSpendingMax(e.target.value)
                    }}
                    style = {{width: '200px'}}
                />
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Använd pott'}
                    disabled={isSaving}
                    value={populateFileSubscriptionSpending}
                    onChange={(e) => {
                        setPopulateFileSubscriptionSpending(e.target.value)
                    }}
                    style = {{width: '200px'}}
                />
            </Box>
        );
    }

    const renderMonthlyQuota = () => {

        const errorText = getErrorMonthlyQuota();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Max anrop per månad (monthly quota)'}
                    disabled={isSaving}
                    value={monthlyQuota}
                    onChange={(e) => {
                        setMonthlyQuota(e.target.value)
                    }}
                    error={errorText !== undefined}
                    helperText={errorText}
                    style = {{width: '200px'}}
                />
            </Box>
        );
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            <Box
                component="form"
                noValidate
                autoComplete="off">

                {renderDivider('Status')}
                {renderUserState()}
                {renderDivider('Rättigheter')}
                {renderPermissions()}
                {renderDivider('Konto')}
                {renderEmail()}
                {renderPhoneNumber()}
                {renderDivider('Användarinformation')}
                {renderName()}
                {renderAddress()}
                {renderZipAndCity()}
                {renderCompany()}
                {renderDivider('Prenumeration')}
                {renderExportSubscription1()}
                {renderExportSubscription2()}
                {renderDivider('Nummersättning')}
                {renderPopulateFileSubscription1()}
                {renderPopulateFileSubscription2()}
                {renderInvoice()}
                {renderDivider('API')}
                {renderMonthlyQuota()}

            </Box>
            <Box paddingTop={'25px'} paddingBottom={'10px'}>
                <Button
                    variant='contained'
                    color='secondary'
                    size={'large'}
                    onClick={actionSave}
                    disabled={isSaving || !isAllValuesValid()}>
                    Spara
                </Button>
            </Box>
        </Box>
    );
}

import { Box, Button, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjAdminBaseData } from '../../../api/object/admin/ApiObjAdminBaseData';
import { ApiObjAdminUserHitrateInstance } from '../../../api/object/admin/ApiObjAdminUserHitrateInstance';
import { api_admin_user_hitrate_instance_custom_field_list } from '../../../api/endpoint/admin/user/hitrate_instance/api_admin_user_hitrate_instance_custom_field_list';
import { ApiObjHitrateCustomField } from '../../../api/object/hitrate/ApiObjHitrateCustomField';
import { ApiObjExportField } from '../../../api/object/export/ApiObjExportField';
import { api_admin_user_hitrate_instance_patch } from '../../../api/endpoint/admin/user/hitrate_instance/api_admin_user_hitrate_instance_patch';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    adminBaseData: ApiObjAdminBaseData
    hitrateInstance: ApiObjAdminUserHitrateInstance
    otherUserId: number
    callbackCloseAndReload: () => void
}

export const AdminUserTabHitrateCustomFields: FunctionComponent <Props> = ({
    baseData,
    userData,
    adminBaseData,
    hitrateInstance,
    otherUserId,
    callbackCloseAndReload
}) => {

    // ======================================================================
    // === Not admin
    // ======================================================================

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    // ======================================================================
    // === Settings
    // ======================================================================

    const STANDARD_EXPORT_FIELDS = [
        ApiObjExportField.ID_7_PHONE_MOBILE,
        ApiObjExportField.ID_8_PHONE_FIXED,
        ApiObjExportField.ID_2_FIRST_NAME,
        ApiObjExportField.ID_3_LAST_NAME,
        ApiObjExportField.ID_24_PERSONAL_ID,
        ApiObjExportField.ID_4_ADDRESS_STREET,
        ApiObjExportField.ID_5_ZIPCODE,
        ApiObjExportField.ID_6_ADDRESS_CITY,
        ApiObjExportField.ID_15_CONTACTS_ID,
    ];

    // ======================================================================
    // === State
    // ======================================================================

    const [isLoading, setIsLoading] = useState <boolean> (true);
    const [isSaving, setIsSaving] = useState <boolean> (false);
    const [isError, setIsError] = useState <boolean> (false);
    
    const [customFields, setCustomFields] = useState <Array<ApiObjHitrateCustomField>> ([]);
    const [formExportFieldsMap, setFormExportFieldsMap] = useState <Map<number, number>> (new Map());
    
    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {
    
        const hitrateInstanceId = hitrateInstance.id;
        setFormExportFieldsMap(new Map());

        setIsLoading(true);
        api_admin_user_hitrate_instance_custom_field_list(otherUserId, hitrateInstanceId)
            .then((result) => {
                if (hitrateInstanceId !== hitrateInstance.id) {
                    // Do nothing if hitrate instance has changed.
                    return;
                }
                setupFormData(result);
                setIsLoading(false);
            }).catch((err) => {
                console.error(err);
            });
        
    }, [hitrateInstance]);

    const setupFormData = (newCustomFields: Array<ApiObjHitrateCustomField>) => {

        const map = new Map <number, number> ();
        for (const item of hitrateInstance.custom_fields) {

            let found = false;
            for (const field of newCustomFields) {
                if (field.id === item.custom_field_id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                continue;
            }
            map.set(item.export_field_id, item.custom_field_id);
        }
        setFormExportFieldsMap(map);

        setCustomFields(newCustomFields);
    }

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionSelectChange = (exportFieldId : number, event: SelectChangeEvent<number>) => {
        const newValue = event.target.value;
        if (typeof newValue !== 'number') {
            return;
        }
        const map = new Map(formExportFieldsMap);
        map.set(exportFieldId, newValue);
        setFormExportFieldsMap(map);
    }

    const actionSave = () => {

        setIsSaving(true);
        setIsError(false);

        const tempCustomFields = [];

        for (const exportField of adminBaseData.export_fields) {
            const customFieldId = formExportFieldsMap.get(exportField.id);
            if (customFieldId !== undefined && customFieldId !== 0) {
                tempCustomFields.push({
                    export_field_id: exportField.id,
                    custom_field_id: customFieldId
                });
            }
        }
        const data = {
            custom_fields: tempCustomFields
        };

        api_admin_user_hitrate_instance_patch(otherUserId, hitrateInstance.id, data)
            .then((result) => {
                setIsSaving(false);
                callbackCloseAndReload();

            }).catch((err) => {
                console.error(err);
                setIsError(true);
                setIsSaving(false);
            });
    }

    // ======================================================================
    // === Errors
    // ======================================================================

    const isErrorExportField = (exportFieldId: number) : boolean => {

        const customFieldId = formExportFieldsMap.get(exportFieldId);
        if (customFieldId === undefined || customFieldId === 0) {
            return false;
        }
        for (const item of adminBaseData.export_fields) {
            if (item.id === exportFieldId) {
                continue;
            }
            const tempId = formExportFieldsMap.get(item.id);
            if (tempId === undefined) {
                continue;
            }
            if (tempId === customFieldId) {
                return true;
            }
        }
        return false;
    }

    const isErrorAnyField = () : boolean => {
        for (const item of adminBaseData.export_fields) {
            if (isErrorExportField(item.id)) {
                return true;
            }
        }
        return false;
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const styleFormItemBox = {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
        alignItems: 'start',
        gap: '12px'
    } as React.CSSProperties;

    const renderFormItems = () : JSX.Element => {

        const formElements : Array<JSX.Element> = [];
        for (const item of adminBaseData.export_fields) {
            formElements.push(renderFormItem(item));
        }

        return (
            <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                {formElements}
            </Box>
        );
    }

    const renderFormItem = (exportField: ApiObjExportField) : JSX.Element => {

        const menuItems = [];
        menuItems.push(
            <MenuItem
                key={0}
                value={0}>
                    {'-'}
            </MenuItem>
        );
        for (const item of customFields) {
            menuItems.push(
                <MenuItem
                    key={item.id}
                    value={item.id}>
                        {item.name}
                </MenuItem>
            );
        }

        let selectValue = formExportFieldsMap.get(exportField.id);
        if (selectValue === undefined) {
            selectValue = 0;
        }

        let selectElement = (
            <Select
                style={{width: '280px'}}
                size={'small'}
                value={selectValue}
                onChange={(event) => {actionSelectChange(exportField.id, event)}}
                error={isErrorExportField(exportField.id)}
                disabled={isLoading || isSaving}>

                {menuItems}

            </Select>
        );
        if (STANDARD_EXPORT_FIELDS.includes(exportField.id)) {
            selectElement = (
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} height={'40px'}>Standardfält</Box>
            );
        }

        return (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Box width={'230px'}>
                    {exportField.name}
                </Box>
                <Box width={'300px'}>
                    {selectElement}
                </Box>
                <Box width={'100px'}>
                    {exportField.is_premium ? 'Premium' : ''}
                </Box>
            </Box>
        );
    }

    const renderStatus = () : JSX.Element|undefined => {

        if (isSaving) {
            return (
                <Box style={styleFormItemBox}>
                    Sparar...
                </Box>
            );
        } else if (isError) {
            return (
                <Box color={'#FF0000'} style={styleFormItemBox}>
                    Ett fel uppstod.
                </Box>
            );
        } else {
            return undefined
        }
    }

    const renderErrorMessage = () : JSX.Element|undefined => {
        if (!isErrorAnyField()) {
            return undefined
        }
        return (
            <Box color={'#FF0000'}>Dubletter existerar</Box>
        );
    }

    if (isLoading) {
        return (<Box padding={'30px'}>Laddar...</Box>);
    }

    return (
        <Box paddingTop={'30px'} display={'flex'} flexDirection={'column'}>
            <Box fontSize={'20px'} paddingBottom={'16px'}>Mappning av kundkortsfält</Box>
            {renderFormItems()}
            <Box display={'flex'} flexDirection={'row'} gap={'10px'} paddingTop={'20px'} alignItems={'center'}>
                <Button variant='contained' color='secondary' onClick={actionSave} disabled={isSaving || isErrorAnyField()}>
                    Spara
                </Button>
                {renderErrorMessage()}
            </Box>
            {renderStatus()}
        </Box>
    );
}

import { FunctionComponent, useState, useEffect } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { api_populate_file_create } from '../../../api/endpoint/populate_file/api_populate_file_create';
import { api_populate_file_get_all } from '../../../api/endpoint/populate_file/api_populate_file_get_all';
import { ApiObjPopulateFile } from '../../../api/object/populate_file/ApiObjPopulateFile';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { util_format_datetime } from '../../../util/format/util_format';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
}

export const PopulateFileUploadPage: FunctionComponent <Props> = ({
    baseData,
    userData,
}) => {

    const [isUploadEnabled, setIsUploadEnabled] = useState <boolean> (true);
    const [data, setData] = useState<ApiObjPopulateFile[]>([])
    
    useEffect(() => {
        if(!userData.isLoggedIn()) return
        api_populate_file_get_all().then((result) => {
            const today = new Date();
            const recentStart = new Date(new Date().setDate(today.getDate() - 2));
            const list = result.filter((item) => moment(item.created_at).isAfter(recentStart) && item.state >= ApiObjPopulateFile.STATE_3_UPLOAD_COMPLETE && item.state <= ApiObjPopulateFile.STATE_9_BUILD_COMPLETE)
            setData(list)
        })
    }, [userData])

    const navigate = useNavigate();

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'rows', headerName: 'Rader', width: 130 },
        { field: 'fileName', headerName: 'Filnamn', width: 130 },
        { field: 'date', headerName: 'Datum', width: 130 },
        { field: 'continue', headerName: 'Fortsätt', renderCell: (params: any) => { 
            return <Button
            variant={'contained'}
            type={'button'}
            color={'secondary'}
            size={'medium'}
            onClick={(e) => {
                e.preventDefault();
                navigate(`/populate_file/${params.id}/order_step_1`)
            }}>
                Fortsätt
        </Button>
         },width: 130 },
      ];

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionSubmit = (file: any) => {

        setIsUploadEnabled(false);

        api_populate_file_create(file)
            .then((result) => {
                navigate('/populate_file/'+result.id+'/order_step_1');
            }).catch((err) => {
                setIsUploadEnabled(true);
            });
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderUploadButton = () : JSX.Element => {

        if (!isUploadEnabled) {
            return (
                <Box mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    Laddar upp fil...
                </Box>
            );
        }

        return (
            <Box mb={2} display={'flex'} flexDirection={'column'} alignItems={'start'}>
                <Button
                    disabled={!isUploadEnabled}
                    variant="contained"
                    component="label"
                    color="primary">

                    {'Ladda upp fil'}
                    <input
                        type="file"
                        name="file"
                        accept=".csv, .xlsx, .xls"
                        onChange={(e) => {
                            if (e.target.files != null && e.target.files[0]) {
                                const tempFile = e.target.files[0];
                                actionSubmit(tempFile);
                            }
                        }}
                        style={{ display: 'none' }}
                    />
                </Button>
            </Box>
        );
    }

    if (!userData.isLoggedIn()) {
        return (
            <Paper style={{padding: '20px'}}>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography variant="h4">{'Nummersättning'}</Typography>
                    <Typography variant="subtitle1">{'Du måste vara inloggad för att använda denna funktion.'}</Typography>
                </Box>
            </Paper>
        );
    }

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="h4">{'Nummersättning'}</Typography>
            <p>Uppdatera era kundregister eller leads ni redan har idag. Hitta nya nummer, adressdata, tvätta mot nix med mera!</p>
            <ol style={{ marginLeft: "-1.5rem" }}>
                <li style={{ marginBottom: "0.5rem" }}>Ladda upp er fil (.xlsx/.csv) och välj rubrik för varje datapunkt</li>
                <li style={{ marginBottom: "0.5rem" }}>Välj data ni vill ha och de tvättar ni vill göra på er lista</li>
                <li style={{ marginBottom: "0.5rem" }}>Vill ni inte ringa samma kund igen? Då kan ni tvätta mot era befintliga stoppfiler i systemet</li>
                <li style={{ marginBottom: "0.5rem" }}>Se offert och översikt på beställning sidan</li>
                <li style={{ marginBottom: "0.5rem" }}>Betala och ladda ned eran uppdaterade data direkt!</li>
            </ol>
            <p>OBS! Dina filer krypteras vid uppladdning och Marknadsurval tar inte del av er data.</p>
            {renderUploadButton()}
            <Typography variant="h6" marginTop={"1.5rem"}>{'Pågående beställningar'}</Typography>
            <div style={{ height: "40rem", width: '100%', margin: "1rem 0" }}>
                <DataGrid
                    rows={data.map((item) => ({
                        id: item.id,
                        rows: item.count.matches,
                        fileName: item.params.name,
                        date: util_format_datetime(item.created_at),
                    }))}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    style={{ height: "25rem" }}
                />
                </div>
        </Box>
    );
};

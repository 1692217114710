export class ApiObjAdminUser {
    
    // ========================================================================
    // === Constants
    // ========================================================================

    static STATE_1_NEW = 1;
    static STATE_2_ACTIVE = 2;
    static STATE_3_SUSPENDED = 3;
    static STATE_4_DELETED = 4;

    // ========================================================================
    // === Fields
    // ========================================================================

    id: number;
    email: string;
    state: number;
    is_admin: boolean;
    is_facebook_enabled: boolean;
    is_hitrate_enabled: boolean;
    is_invoice_enabled: boolean;
    first_name: string;
    last_name: string;
    company_name: string;
    company_org_number: string;
    address: string;
    zipcode: string;
    city: string;
    phone_number: string;
    api_monthly_quota: number;
    export_subscription: {
        type: number,
        price: number,
        leads: number,
        leads_max: number,
    };
    populate_file_subscription: {
        type: number,
        price: number,
        spending: number,
        spending_max: number
    };
    created_at: Date;
    updated_at: Date;

    // ========================================================================
    // === Create
    // ========================================================================

    constructor(data: any) {

        this.id = parseInt(data.id);
        this.email = data.email;
        this.state = parseInt(data.state);
        this.is_admin = parseInt(data.is_admin) === 1;
        this.is_facebook_enabled = parseInt(data.is_facebook_enabled) === 1;
        this.is_hitrate_enabled = parseInt(data.is_hitrate_enabled) === 1;
        this.is_invoice_enabled = parseInt(data.is_invoice_enabled) === 1;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.company_name = data.company_name;
        this.company_org_number = data.company_org_number;
        this.address = data.address;
        this.zipcode = data.zipcode;
        this.city = data.city;
        this.phone_number = data.phone_number;
        this.api_monthly_quota = parseInt(data.api_monthly_quota);
        this.export_subscription = {
            type: parseInt(data.export_subscription.type),
            price: parseInt(data.export_subscription.price),
            leads: parseInt(data.export_subscription.leads),
            leads_max: parseInt(data.export_subscription.leads_max),
        };
        this.populate_file_subscription = {
            type: parseInt(data.populate_file_subscription.type),
            price: parseInt(data.populate_file_subscription.price),
            spending: parseFloat(data.populate_file_subscription.spending),
            spending_max: parseInt(data.populate_file_subscription.spending_max),
        };
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }

    getStateName () : string {
        switch (this.state) {
            case ApiObjAdminUser.STATE_1_NEW: return 'Ny användare';
            case ApiObjAdminUser.STATE_2_ACTIVE: return 'Aktiv';
            case ApiObjAdminUser.STATE_3_SUSPENDED: return 'Avstängd';
            case ApiObjAdminUser.STATE_4_DELETED: return 'Borttagen';
        }
        return '';
    }
}

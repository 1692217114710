import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjHitrateCampaign } from '../../object/hitrate/ApiObjHitrateCampaign';

export const api_hitrate_campaign_list = async (hitrateId: number) : Promise<Array<ApiObjHitrateCampaign>> => {

    const url = API_URL+'/hitrate/'+hitrateId+'/campaign';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };

    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjHitrateCampaign(item));
    }
    return ret;
}

export class ApiObjHitrate {
    
    id: number;
    public_name: string;
    custom_fields: Array<{export_field_id: number, custom_field_id: number}>;

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.public_name = data.public_name;
        this.custom_fields = [];
        for (const item of data.custom_fields) {
            this.custom_fields.push({
                export_field_id: parseInt(item.export_field_id),
                custom_field_id: parseInt(item.custom_field_id)
            });
        }
    }
}
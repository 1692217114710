import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';
import { ApiObjAdminUserHitrateInstance } from '../../../../object/admin/ApiObjAdminUserHitrateInstance';

export const api_admin_user_hitrate_instance_list = async (userId: number) : Promise<Array<ApiObjAdminUserHitrateInstance>> => {

    const url = API_URL+'/admin/user/'+userId+'/hitrate_instance';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };

    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjAdminUserHitrateInstance(item));
    }
    return ret;
}

import { Paper, Box, } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { api_admin_get_users } from '../../../api/endpoint/admin/api_admin_get_users';
import { util_format_datetime } from '../../../util/format/util_format';
import { ApiObjAdminUser } from '../../../api/object/admin/ApiObjAdminUser';

type RowData = {
    id: number,
    email: string,
    created_at: string,
    state: string
}

type Props = {
    userData: UserData,
}

export const AdminUsersListPage: FunctionComponent <Props> = ({
    userData,
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [userObjs, setUserObjs] = useState <Array<ApiObjAdminUser>> ([]);

    useEffect(() => {

        api_admin_get_users()
            .then((result) => {
                setUserObjs(result);

            }).catch((err) => {
                console.error("Could not fetch users");
                console.error(err);
            })
    }, []);
  
    // ======================================================================
    // === List content
    // ======================================================================

    const columns = [

        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            editable: false
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            editable: false,
            renderCell: (params: any) => {
                return (<Link to={'/admin/users_new/'+params.row.id}>{params.row.email}</Link>);
            }
        },
        {
            field: 'created_at',
            headerName: 'Skapad',
            width: 200,
            editable: false,
        },
        {
            field: 'state',
            headerName: 'Status',
            width: 200,
            editable: false,
        },
    ];

    const getRows = () => {
        const ret : Array<RowData> = [];
        for (const item of userObjs) {
            ret.push({
                id: item.id,
                email: item.email,
                created_at: util_format_datetime(item.created_at),
                state: item.getStateName(),
            });
            
        }
        return ret;
    }

    // ======================================================================
    // === Render
    // ======================================================================

    return (
        <Box>
            <Paper>
                <Box>
                    <Box height={'770px'}>
                        <DataGrid
                            rows={getRows()}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            checkboxSelection={false}
                            isRowSelectable={() => {return false;}}
                        />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjAdminUser } from '../../object/admin/ApiObjAdminUser';

export const api_admin_get_users = async () : Promise<Array<ApiObjAdminUser>> => {
    
    const url = API_URL+'/admin/user';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    
    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjAdminUser(item));
    }
    return ret;
}

import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';
import { ApiObjHitrateCustomField } from '../../../../object/hitrate/ApiObjHitrateCustomField';

export const api_admin_user_hitrate_instance_custom_field_list = async (userId: number, hitrateInstanceId: number) : Promise<Array<ApiObjHitrateCustomField>> => {

    const url = API_URL+'/admin/user/'+userId+'/hitrate_instance/'+hitrateInstanceId+'/custom_field';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true
    };

    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjHitrateCustomField(item));
    }
    return ret;
}

import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export interface AdminUserDetailsParams {
    email?: string
    state?: number
    is_facebook_enabled?: boolean
    is_hitrate_enabled?: boolean
    is_invoice_enabled?: boolean
    first_name?: string
    last_name?: string
    company_name?: string
    company_org_number?: string
    address?: string
    zipcode?: string
    city?: string
    phone_number?: string
    api_monthly_quota?: number
    export_subscription_type?: number
    export_subscription_price?: number
    export_subscription_leads?: number
    export_subscription_leads_max?: number
    populate_file_subscription_type?: number
    populate_file_subscription_price?: number
    populate_file_subscription_spending?: number
    populate_file_subscription_spending_max?: number
}

export const api_admin_user_save_details = async (userId: number, data: AdminUserDetailsParams) : Promise<void> => {

    const url = API_URL+'/admin/user/'+userId+'/details';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    await Axios.patch(
        url,
        data,
        config
    );
}
import { useState, useEffect, FunctionComponent } from 'react';
import { 
    Box,
    Button,
    Checkbox,
    Typography,
    TextField,
    Select,
    MenuItem,
    SelectChangeEvent,
    InputLabel,
    FormHelperText,
    CircularProgress,
} from '@mui/material';
import '../../../../sass/offerComponent.sass';
import InjectedCheckoutForm from '../components/CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import DialogChildBoxComponent from '../../../../components/DialogChildBoxComponent';
import TermsDialogComp from '../components/TermsDialogComp';
import { OrderManager } from '../../../../data_layer/order/OrderManager';
import { OrderData } from '../../../../data_layer/order/OrderData';
import { UserData } from '../../../../data_layer/user/UserData';
import { ApiObjBaseData } from '../../../../api/object/base_data/ApiObjBaseData';
import { UserManager } from '../../../../data_layer/user/UserManager';
import { CountOrderData } from '../../../../data_layer/count/order/CountOrderData';
import { CreateLinkOrderDataComp } from '../components/CreateLinkOrderDataComp';
import { FacebookTargetGroupModal } from '../../../shared_components/FacebookTargetGroupModal';
import { ApiObjFilter } from '../../../../api/object/filter/ApiObjFilter';
import { util_format_number } from '../../../../util/format/util_format';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData
    userManager: UserManager
    orderData: OrderData,
    orderManager: OrderManager
}

export const Step5PurchaseComp: FunctionComponent<Props> = ({
    baseData,
    userData,
    userManager,
    orderData,
    orderManager
}) => {

    const [termsOpen, setTermsOpen] = useState(false);
    const navigate = useNavigate();

    const [userFirstName, setUserFirstName] = useState('');
    const [userCompanyName, setUserCompanyName] = useState('');
    const [userAddress, setUserAddress] = useState('');
    const [userZipcode, setUserZipcode] = useState('');
    const [userCity, setUserCity] = useState('');
    const [isFacebookModalOpen, setIsFacebookModalOpen] = useState <boolean> (false);

    useEffect(() => {
        if (!userData.isLoggedIn()) {
            setUserFirstName('');
            setUserCompanyName('');
            setUserAddress('');
            setUserZipcode('');
            setUserCity('');
            return;
        }
        if (!userData.isIdle()) {
            return;
        }

        if (userData.isLoggedIn()) {
            setUserFirstName(userData.getFirstName());
            setUserCompanyName(userData.getCompanyName());
            setUserAddress(userData.getAddress());
            setUserZipcode(userData.getZipcode());
            setUserCity(userData.getCity());
        }
    }, [userData]);

    // ===========================================================
    // === Actions
    // ===========================================================

    const actionFilenameChanged = (newValue: string) => {
        orderManager.actionSetFilename(newValue);
    }

    const actionPaymentMethodChanged = (event: SelectChangeEvent<number>) => {
        const newValue = event.target.value;
        if (typeof newValue === 'number') {
            orderManager.actionSetPaymentMethod(newValue);
        }
    }

    const actionToggleTermsAccepted = () => {
        orderManager.actionToggleTermsAccepted();
    }

    const actionUserInfoSaveClicked = () => {

        const newValues = {
            first_name: userFirstName,
            company_name: userCompanyName,
            address: userAddress,
            zipcode: userZipcode,
            city: userCity
        };
        userManager.actionUpdateUserData(newValues);
    }

    const actionPurchaseClicked = () => {
        orderManager.actionPurchaseStart();
    }

    const actionAbortPurchaseClicked = () => {
        orderManager.actionResetPurchaseState();
    }

    const actionStripeSubmit = (paymentIntent: any) => {
        if (paymentIntent && paymentIntent.status === 'succeeded') {
            orderManager.actionPurchaseStripeSuccess();
        } else {
            orderManager.actionPurchaseStripeFailed();
        }
    };

    const actionCloseDialogExportFailed = () => {
        orderManager.actionResetPurchaseState();
    }

    const actionCloseDialogStripeFailed = () => {
        orderManager.actionResetPurchaseState();
    }

    const actionCloseDialogSuccess = () => {
        orderManager.actionOrderReset();
        navigate('/my_files');
    }

    // ===========================================================
    // === Helpers
    // ===========================================================

    const isVitalUserInfoMissing = () : boolean => {
        return userData.isLoggedIn()
            && (
                userData.getFirstName() === ''
                || userData.getCompanyName() === ''
                || userData.getAddress() === ''
                || userData.getZipcode() === ''
                || userData.getCity() === ''
            );
    }

    const getForbiddenFilters = () : Array<ApiObjFilter> => {

        if (!userData.isLoggedIn()) {
            return [];
        }

        const result = [];
        for (const item of orderData.filter_ids_selected) {
            const tempFilter = baseData.filter_map.get(item);

            if (tempFilter === undefined) {
                continue;
            }
            if (!userData.isFilterEnabledForExport(tempFilter)) {
                result.push(tempFilter);
            }
        }
        return result;
    }

    type LocalFilterGroup = {
        id: number,
        name: string,
        sort_order: number,
        children: Array<LocalFilterGroup>,
    }

    const getFilterGroups = () : Array<LocalFilterGroup> => {
    
        const groupMap = new Map<number, LocalFilterGroup>();
    
        for (const filterId of orderData.filter_ids_selected) {
    
            const filter = baseData.filter_map.get(filterId);
            if (filter === undefined) {
                console.log("Filter not found. filterId: "+filterId);
                continue;
            }
    
            const topParent = getTopParent(filterId);
            if (topParent === undefined) {
                console.log("Filter top parent not found. filterId: "+filterId);
                continue;
            }
            let group = groupMap.get(topParent.getId());
            if (group === undefined) {
                group = {
                    id: topParent.getId(),
                    name: topParent.getName(),
                    sort_order: topParent.getSortOrder(),
                    children: [],
                }
                groupMap.set(topParent.getId(), group);
            }
            group.children.push({
                id: filter.getId(),
                name: filter.getName(),
                sort_order: filter.getSortOrder(),
                children: []
            });
        }
    
        const ret : Array<LocalFilterGroup> = [];
        groupMap.forEach((value: LocalFilterGroup) => {
            value.children.sort((a : LocalFilterGroup, b : LocalFilterGroup) => (a.sort_order - b.sort_order));
            ret.push(value);
        });
        ret.sort((a : LocalFilterGroup, b : LocalFilterGroup) => (a.sort_order - b.sort_order));
        return ret;
    }
    
    const getTopParent = (filterId: number) : ApiObjFilter|undefined => {
        const filter = baseData.filter_map.get(filterId);
        if (filter === undefined) {
            return undefined;
        }
        if (filter.getParentId() === 0) {
            return filter;
        }
        return getTopParent(filter.getParentId());
    }

    // ===========================================================
    // === Render helpers
    // ===========================================================

    const createTopButtons = () : JSX.Element => {

        let facebookButton = undefined;
        if (userData.isFacebookEnabled()) {
            facebookButton = (
                <Button
                    variant={'contained'}
                    type={'button'}
                    color={'primary'}
                    size={'medium'}
                    onClick={(e) => {
                        e.preventDefault();
                        setIsFacebookModalOpen(true);
                    }}>
                        {'Dela till Meta'}
                </Button>
            )
        }

        return (
            <Box display={'flex'} flexDirection={'row'} justifyContent={'end'} gap={'12px'}>
                {facebookButton}
                <CreateLinkOrderDataComp
                    userData={userData}
                    orderData={orderData}
                />
            </Box>
        );
    }

    const createBlockNixOption = () : JSX.Element => {
        let nixOption = baseData.getNixOption(orderData.nix_option_id);
        if (nixOption === undefined && userData.isLoggedIn()) {
            nixOption = userData.getNixOption(orderData.nix_option_id);
        }
        let text = '';
        if (nixOption !== undefined) {
            text = nixOption.name;
        }
        return (
            <Box className="offer-block">
                <Typography variant="h6">{'NIX-alternativ'}</Typography>
                <Typography variant="body2">{text}</Typography>
            </Box>
        );
    }


    const createBlockFilters = () => {

        const groups = getFilterGroups();

        const groupElements = [];
        for (const group of groups) {
            const childNames = [];
            for (const child of group.children) {
                childNames.push(child.name);
            }
            groupElements.push(
                <Box key={group.id} mt={2} mb={2} mr={4}>
                    <Typography variant="subtitle1">{group.name}:</Typography>
                    <div className="selection-items">
                        <Typography variant="body2">
                            {childNames.join(', ')}
                        </Typography>
                    </div>
                </Box>
            );
        }
        return (
            <Box className="offer-block">
                <Typography variant="h6">{'Urval - din valda målgrupp'}</Typography>
                {groupElements}
            </Box>
        );
    }

    const createBlockExportFields = () => {

        const childElements = [];
        for (const item of baseData.export_fields) {
            if (orderData.export_field_ids.includes(item.id)) {
                childElements.push(
                    <Box key={item.id}>
                        <Typography variant="body2">{item.name}</Typography>
                    </Box>
                );
            }
        }
        return (
            <Box className="offer-block">
                <Typography variant="h6">{'Valbart - innehåll i din leveransfil'}</Typography>
                {childElements}
            </Box>
        );
    }

    const createFormItems = () => {

        let errorFilenameElement = undefined;
        if (orderData.filename.length == 0) {
            errorFilenameElement = (<FormHelperText error={true}>Du måste ange ett filnamn</FormHelperText>);
        } else if (orderData.filename.length > 50) {
            errorFilenameElement = (<FormHelperText error={true}>Filnamnet är för långt</FormHelperText>);
        }

        let errorPaymentElement = undefined;
        
        if (orderData.payment_method == OrderData.PAYMENT_METHOD_SUBSCRIPTION && userData.isLoggedIn()) {
            const leadsLeft = userData.getExportSubscriptionLeadsLeft();
            if (
                orderData.order_count !== undefined
                && orderData.order_count.api_data !== undefined
            ) {
                let leadsCount = orderData.order_count.api_data.counts.final_count;
                if (orderData.size_limit > 0 && orderData.size_limit < leadsCount) {
                    leadsCount = orderData.size_limit;
                }

                if (leadsCount > leadsLeft) {
                    const leadsLeftText = util_format_number(leadsLeft);
                    errorPaymentElement = (<FormHelperText error={true}>Du har för få leads kvar. Lägg till begränsning eller välj annan betalmetod. Återstående leads: {leadsLeftText}</FormHelperText>);
                }
            }
        }

        return (
            <Box className="offer-block" display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} flexDirection={'column'}>
                    <InputLabel id="label_filename">Filnamn</InputLabel>
                    <TextField
                        id="export_filename"
                        disabled={!orderData.canEditOrder()}
                        variant="outlined"
                        value={orderData.filename}
                        onChange={(e) => actionFilenameChanged(e.target.value)}
                        error={errorFilenameElement !== undefined}
                        style = {{width: '300px'}}
                    />
                    {errorFilenameElement}
                </Box>
                <Box display={'flex'} flexDirection={'column'} marginTop={2} width={'300px'}>
                    <InputLabel id="label_payment_method">Betalmetod</InputLabel>
                    <Select
                        value={orderData.payment_method}
                        onChange={(event) => {actionPaymentMethodChanged(event)}}
                        disabled={!orderData.canEditOrder()}
                        error={errorPaymentElement !== undefined}>

                        <MenuItem
                            key={1}
                            value={1}>
                                {'Prenumeration'}
                        </MenuItem>
                        <MenuItem
                            key={2}
                            value={2}>
                                {'Betalkort'}
                        </MenuItem>

                    </Select>
                    {errorPaymentElement}
                </Box>
            </Box>
        );
    }

    const tryCreateUserForm = () => {

        if (!userData.isLoggedIn() || !isVitalUserInfoMissing()) {
            return undefined;
        }
        
        let errorFistName = undefined;
        if (userFirstName.length == 0) {
            errorFistName = 'Måste anges';
        } else if (userFirstName.length > 50) {
            errorFistName = 'För långt';
        }

        let errorCompanyname = undefined;
        if (userCompanyName.length == 0) {
            errorCompanyname = 'Måste anges';
        } else if (userCompanyName.length > 50) {
            errorCompanyname = 'För långt';
        }

        let errorAddress = undefined;
        if (userAddress.length == 0) {
            errorAddress = 'Måste anges';
        } else if (userAddress.length > 100) {
            errorAddress = 'För långt';
        }

        let errorZipcode = undefined;
        if (userZipcode.length != 5) {
            errorZipcode = 'Svenskt postnummer 5 siffror';
        }

        let errorCity = undefined;
        if (userCity.length == 0) {
            errorCity = 'Måste anges';
        } else if (userCity.length > 50) {
            errorCity = 'För långt';
        }

        const isFormDataValid = errorFistName === undefined
            && errorCompanyname === undefined
            && errorAddress === undefined
            && errorZipcode === undefined
            && errorCity === undefined;

        let saveFailedElement = undefined;
        if (userData.state === UserData.STATE_UPDATE_USER_FAILED) {
            saveFailedElement = (<div>Misslyckades att spara dina uppgifter.</div>);
        }

        return (
            <Box className="offer-block">
                <div>Du behöver komplettera dina uppgifter innan du kan genomföra köpet.</div>
                <Box mt={3}>
                    <TextField
                        id="user_first_name"
                        label={'Förnamn'}
                        variant="outlined"
                        value={userFirstName}
                        onChange={(e) => setUserFirstName(e.target.value)}
                        error={errorFistName !== undefined}
                        helperText={errorFistName}
                    />
                </Box>
                <Box mt={3}>
                    <TextField
                        id="user_company_name"
                        label={'Företagsnamn'}
                        variant="outlined"
                        value={userCompanyName}
                        onChange={(e) => setUserCompanyName(e.target.value)}
                        error={errorCompanyname !== undefined}
                        helperText={errorCompanyname}
                    />
                </Box>
                <Box mt={3}>
                    <TextField
                        id="user_address"
                        label={'Adress'}
                        variant="outlined"
                        value={userAddress}
                        onChange={(e) => setUserAddress(e.target.value)}
                        error={errorAddress !== undefined}
                        helperText={errorAddress}
                    />
                </Box>
                <Box mt={3}>
                    <TextField
                        id="user_zip_code"
                        label={'Postnummer'}
                        variant="outlined"
                        value={userZipcode}
                        onChange={(e) => setUserZipcode(e.target.value)}
                        error={errorZipcode !== undefined}
                        helperText={errorZipcode}
                    />
                </Box>
                <Box mt={3}>
                    <TextField
                        id="user_city"
                        label={'Ort'}
                        variant="outlined"
                        value={userCity}
                        onChange={(e) => setUserCity(e.target.value)}
                        error={errorCity !== undefined}
                        helperText={errorCity}
                    />
                </Box>
                <Box mt={3}>
                    <Button
                        title={'Spara'}
                        disabled={!(isFormDataValid && userData.isIdle())}
                        variant={'contained'}
                        color={'secondary'}
                        size={'medium'}
                        onClick={actionUserInfoSaveClicked}>
                        {'Spara'}
                    </Button>
                </Box>
                {saveFailedElement}
            </Box>
        );
    }

    const tryCreatePurchaseButton = () => {

        if (!userData.isLoggedIn()) {
            return (
                <Box className="offer-block">
                    <div>Du måste vara inloggad för att kunna genomföra köpet.</div>
                </Box>
            );
        }

        if (isVitalUserInfoMissing()) {
            return undefined;
        }

        const forbiddenFilters = getForbiddenFilters();
        if (forbiddenFilters.length > 0) {
            const forbiddenElements = [];
            for (const item of forbiddenFilters) {
                forbiddenElements.push(
                    <Box key={item.getId()}>
                        {item.getName()}
                    </Box>
                );
            }
            return (
                <Box className="offer-block">
                    <Box display={'flex'} flexDirection={'column'}>
                        <Box style={{color: 'red'}}>Dessa filter kan endast användas för att dela till Meta:</Box>
                        <Box display={'flex'} flexDirection={'column'} paddingTop={'10px'}>
                            {forbiddenElements}
                        </Box>
                    </Box>
                </Box>
            );
        }

        let leadsCount = -1;
        if (
            orderData.order_count !== undefined
            && orderData.order_count.state === CountOrderData.STATE_READY
            && orderData.order_count.api_data !== undefined
        ) {
            leadsCount = orderData.order_count.api_data.counts.final_count;
            if (orderData.size_limit > 0 && orderData.size_limit < leadsCount) {
                leadsCount = orderData.size_limit;
            }
        }

        let errorElement = undefined;
        if (leadsCount != -1 && leadsCount < 100) {
            errorElement = (
                <Box mt={3}>
                    Slutgiltigt antal är för litet ({leadsCount}). Minsta tillåtna antal: 100.
                </Box>
            );

        } else if (leadsCount > 100000) {
            errorElement = (
                <Box mt={3}>
                    Slutgiltigt antal är för stort ({util_format_number(leadsCount)}). Största tillåtna antal: 100 000. Klicka "lägg till begränsning" till höger.
                </Box>
            );
        }

        let abortElement = undefined;
        if (orderData.purchase_state !== OrderData.PURCHASE_STATE_1_NOT_STARTED) {
            abortElement = (
                <Box marginLeft={2}>
                    <Button
                        variant={'contained'}
                        style={{width: '300px'}}
                        size={'large'}
                        color={'secondary'}
                        disabled={orderData.purchase_state !== OrderData.PURCHASE_STATE_4_WAITING_FOR_STRIPE_INPUT}
                        onClick={actionAbortPurchaseClicked}>
                            Avbryt
                    </Button>
                </Box>
            );
        }

        let loadingElement = undefined;
        if (orderData.purchase_state === OrderData.PURCHASE_STATE_2_CREATING_EXPORT) {
            loadingElement = (
                <Box marginTop={'16px'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Box><CircularProgress /></Box>
                    <Box marginLeft={'12px'}>Hanterar beställning...</Box>
                </Box>
            );
        }

        const style = {
            "cursor": "pointer",
            "&:hover": {
                "text-decoration": "underline"
            }
        }

        return (
            <Box marginLeft={2}>
                <Box display="flex" alignItems="center" flexDirection={'row'}>
                    <Checkbox
                        checked={orderData.terms_accepted}
                        disabled={!orderData.canEditOrder()}
                        onChange={actionToggleTermsAccepted}
                        color="secondary"
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography style={style} variant="body2" onClick={() => {setTermsOpen(true)}} className="purchase">
                        {'Jag har läst och accepterar köpvillkoren'}
                    </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'row'} marginTop={3}>
                    <Button
                        variant={'contained'}
                        style={{width: '300px'}}
                        size={'large'}
                        color={'secondary'}
                        disabled={!orderData.canMakePurchase(userData)}
                        onClick={actionPurchaseClicked}>
                            Köp
                    </Button>
                    {abortElement}
                </Box>
                {loadingElement}
                {errorElement}
            </Box>
        );
    }

    const tryCreateStripeCheckout = () => {
        const isStripeActive = orderData.purchase_state === OrderData.PURCHASE_STATE_4_WAITING_FOR_STRIPE_INPUT;
        if (isStripeActive) {
            const stripePromise = loadStripe(orderData.purchase_stripe_public_key); // Might need to live outside render.
            return (
                <Box marginTop={5}>
                    <Typography variant="h6">{'Kortbetalning'}</Typography>
                    <Box width={'600px'}>
                        <Elements stripe={stripePromise}>
                            <InjectedCheckoutForm
                                paymentIntentClientSecret={orderData.purchase_stripe_client_secret}
                                handleSubmit={actionStripeSubmit} /> 
                        </Elements>
                    </Box>
                </Box>
            );
        }
        return undefined;
    }

    const tryCreateTermsDialog = () => {
        
        if (termsOpen) {
            return (
                <TermsDialogComp
                    open={true}
                    setOpen={() => setTermsOpen(false)}
                />
            );
        }
        return undefined;
    }

    const tryCreateExportFailedDialog = () => {
        const isActive = orderData.purchase_state === OrderData.PURCHASE_STATE_3_EXPORT_FAILED;
        if (isActive) {
            return (
                <DialogChildBoxComponent
                    newOpen={true}
                    setNewOpen={actionCloseDialogExportFailed}
                    title={'Kunde inte skapa export'}
                    bodyText={'Oväntat fel.'}
                />
            );
        }
        return undefined;
    }

    const tryCreateStripeFailedDialog = () => {
        const isActive = orderData.purchase_state === OrderData.PURCHASE_STATE_5_STRIPE_FAILED;
        if (isActive) {
            return (
                <DialogChildBoxComponent
                    newOpen={true}
                    setNewOpen={actionCloseDialogStripeFailed}
                    title={'Din betalning misslyckades'}
                    bodyText={'Något gick fel med ditt kort. Du kan försöka igen med samma eller ett annat kort.'}
                />
            );
        }
        return undefined;
    }

    const tryCreateSuccessDialog = () => {

        const isActive = orderData.purchase_state === OrderData.PURCHASE_STATE_6_SUCCESS;

        if (isActive) {
            const textBody = (orderData.payment_method === OrderData.PAYMENT_METHOD_STRIPE) ?
                'Din betalning genomfördes. Leveransfilen med all data hittar du under Mina filer. Du kommer att omdirigeras till Mina filer när du stänger ner rutan.'
                : 'Beställning som slutförts som en del av din prenumeration. Leveransfilen med all data hittar du under Mina filer. Du kommer att omdirigeras till Mina filer när du stänger ner rutan.';

            return (
                <DialogChildBoxComponent
                    newOpen={true}
                    setNewOpen={actionCloseDialogSuccess}
                    title={'Grattis!'}
                    bodyText={textBody}
                />
            );
        }
        return undefined;
    }

    const tryRenderFacebookModal = () : undefined|JSX.Element => {

        if (!isFacebookModalOpen) {
            return undefined;
        }

        const actionClose = () => {
            setIsFacebookModalOpen(false);
        }

        return (
            <FacebookTargetGroupModal
                target_group={undefined}
                new_target_group_data={{
                    name: orderData.filename,
                    source_id: orderData.source_id,
                    nix_option_id: orderData.nix_option_id,
                    filter_ids: orderData.filter_ids_selected,
                    map_areas: orderData.map_areas,
                    export_field_ids: orderData.export_field_ids,
                    stopfile_ids: [],           // Do not add stopfiles.
                    stopfile_days: 0,
                    household_option_id: orderData.household_option_id
                }}
                baseData={baseData}
                userData={userData}
                onClose={actionClose}
             />
        );
    }

    // ===========================================================
    // === Render Main
    // ===========================================================

    return (
        <Box className="offer-container">
            {createTopButtons()}
            {createBlockNixOption()}
            {createBlockFilters()}
            {createBlockExportFields()}
            {createFormItems()}
            {tryCreateUserForm()}
            {tryCreatePurchaseButton()}
            {tryCreateStripeCheckout()}
            {tryCreateTermsDialog()}
            {tryCreateExportFailedDialog()}
            {tryCreateStripeFailedDialog()}
            {tryCreateSuccessDialog()}
            {tryRenderFacebookModal()}
        </Box>
    );
};

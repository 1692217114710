export class ApiObjAdminUserHitrateInstance {
    
    id: number;
    user_id: number;
    is_active: boolean;
    public_name: string;
    url: string;
    api_token: string;
    custom_fields: Array<{export_field_id: number, custom_field_id: number}>;

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.user_id = parseInt(data.user_id);
        this.is_active = parseInt(data.is_active) === 1;
        this.public_name = data.public_name;
        this.url = data.url;
        this.api_token = data.api_token;
        this.custom_fields = [];
        for (const item of data.custom_fields) {
            this.custom_fields.push({
                export_field_id: parseInt(item.export_field_id),
                custom_field_id: parseInt(item.custom_field_id)
            });
        }
    }
}
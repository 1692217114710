import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjHitrateCustomField } from '../../object/hitrate/ApiObjHitrateCustomField';

export const api_hitrate_custom_field_list = async (hitrateId: number) : Promise<Array<ApiObjHitrateCustomField>> => {

    const url = API_URL+'/hitrate/'+hitrateId+'/custom_field';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };

    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push({
            id: parseInt(item.id),
            name: item.name
        });
    }
    return ret;
}
